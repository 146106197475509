import { Header, } from 'semantic-ui-react'
import { Route, Routes } from 'react-router';
import PCAList from './components/pca-list.component';
import PCAEditComponent from './components/pca-edit.component';

const PCAPage = () => {


 const GetPageRoutes = function() {
        return (
          <Routes>
            <Route path=":id" element={<PCAEditComponent />}/>
            <Route path="new" element={<PCAEditComponent />} />
            <Route path="/" element={<PCAList />}/>
          </Routes>
        )
 } 
 return (
   <div>
    <Header as='h2' className='left' textAlign='left'>
        <Header.Content>
            Project Competitiveness Assessment (PCA)
            <Header.Subheader>
            Manage PCA data for the application <br></br>
            For this phase, a single PCA would be used as default PCA.
            </Header.Subheader>
        </Header.Content>
    </Header>
    <GetPageRoutes />
   </div>
 );
};

export default PCAPage;