import { useState, useEffect, useMemo } from "react";
import { delay } from "q";
import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  Form,
  Icon,
  Label,
  Segment,
  Dimmer,
  Header,
} from "semantic-ui-react";
import {
  CodeTable,
  getCodeTableDetail,
  getCodeTableList,
  saveCodeTable,
} from "../codetable.service";
import CodeTableValues from "./codetable-edit-values.component";

const CodeTableEditComponent = () => {
  const params = useParams();
  const idParam = params?.id;
  const codeTableId = +params?.id;
  
  const defaultData = useMemo(
    () => ( 
      { id: undefined, name: '', typeCode: "", isActive: true, description: '' }
    ),[])

  const [editState, setEditState] = useState(defaultData);
  const [dimmerActive, setDimmerActive] = useState(false);
  const [originalTypeCode, setOriginalTypeCode] = useState("");
  const navigate = useNavigate();
  const [isEditable, setEditable] = useState(typeof idParam === "undefined");
  const [inProgress,setInProgress]=useState(false) 
 const [ParentList, setParentList] = useState(
    [
      {
        value: 'type_of_project', text: 'Project Type'
      },
      {
        value: 'project_category', text: 'Project Category'
      },
     
    ]
  );
  useEffect(() => {
    if (codeTableId) {
      getCodeTableDetail(codeTableId,(result)=>{

        if (result.data) {
          let stateData = { ...defaultData };
          Object.assign(stateData, result.data);
          if (!stateData["description"]) {
            stateData["description"] = "";
          }
          setEditState(stateData);
          setOriginalTypeCode(result.data.typeCode);
        }
   
      });
    }
  }, [codeTableId, defaultData]);

 
  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === "checkbox") {
      value = data.checked;
    }
  

    setEditState((prevState) => ({ ...prevState, [name]: value }));
    if (name === "name" && !originalTypeCode) {
      const strValue: string = value;
      const typeCode = strValue.toLowerCase().replaceAll(" ", "_");
      setEditState((prevState) => ({ ...prevState, typeCode: typeCode }));
    }
  };

  const handleOnClickEdit = () => {
    setEditable(true);
  };

  const handleSubmit = () => {
    const codeTableData: CodeTable = editState;
    setInProgress(true)
    try {
      saveCodeTable(codeTableData, (result: any) => {
        if ([201, 200].includes(result.status)) {
          setDimmerActive(true);
          delay(1000).then(() => {
            setDimmerActive(false);
            navigate(`/admin/codetable/${result.data.id}`);
          });
        } else {
         
        }
      })
        .then(() => {
         
        })
        .catch((error) => {
         
        }).finally(() => {
          setInProgress(false)
        }
        );
    } catch (error) {
     
    }
  };

  return (
    <div>
      <Segment raised>
        <Label as="a" color="red" ribbon>
          Code Table Details
        </Label>
       
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="name"
              label="Code Table Name"
              placeholder="Code Table Name"
              required
              onChange={handleChange}
              readOnly={!isEditable}
              value={editState.name}
            />
            <Form.Input
              fluid
              name="typeCode"
              label="Key"
              placeholder="Key referenced in application code"
              required
              onChange={handleChange}
              value={editState.typeCode}
              readOnly={!isEditable}
            />
          </Form.Group>
          <Form.TextArea
            name="description"
            label="Description"
            placeholder="Description about where the code table is used"
            onChange={handleChange}
            value={editState.description}
            readOnly={!isEditable}
          />
 
          <Form.Group inline>
            <label>
              {isEditable
                ? "Active"
                : editState.isActive
                ? "Active: Yes"
                : "Inactive"}
            </label>
            {isEditable && (
              <Form.Checkbox
              
                name="isActive"
                defaultChecked={editState.isActive === true}
                toggle
                readOnly={!isEditable}
               
                onChange={handleChange}
              />
            )}
          </Form.Group>
          {isEditable && (
            <Form.Button color="blue" disabled={inProgress} icon>
              <Icon name="save" /> Save
            </Form.Button>
          )}
           {!isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="edit" /> Edit
            </Form.Button>
          )}
        </Form>
        <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>
        <Dimmer active={dimmerActive} page>
          <Header as="h2" icon inverted>
            <Icon name="check" color="green" />
            Saved successfully!
            <Header.Subheader>Redirecting you to list page</Header.Subheader>
          </Header>
        </Dimmer>
      </Segment>
      <Divider />

      <CodeTableValues codeTableId={codeTableId} codeTable={editState} />
    </div>
  );
};

export default CodeTableEditComponent;
