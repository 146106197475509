import { useState, useEffect, useMemo} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  Form,
  Icon,
  Label,
  Segment,
  Dimmer,
  Header,
} from "semantic-ui-react";
import { PCA, getPCADetail, savePCA } from "../pca.service";
import PCAProjectComponents from "./pca-edit-components.component";
import { dimDelayNavigate } from "../../../utils/common-utils";
const PCAEditComponent = () => {
  const params = useParams()
  const idParam = params?.id
  const pcaId = +params?.id
  const defaultData = useMemo(
  () => ( 
    { name: '', isActive: true, description: '', isDefault: false }
  ),[])
  const [dimmerActive, setDimmerActive] = useState(false)
  const [editState,setEditState] = useState(defaultData)
  const navigate = useNavigate()
  const [isEditable, setEditable] = useState(typeof idParam === 'undefined')
  const [inProgress,setInProgress]=useState(false) 
  useEffect(() => {
    if(pcaId) {
      
      getPCADetail(pcaId,(result)=>{
              
        if (result.data) {
          let stateData = {...defaultData};
            Object.assign(stateData, result.data)
            if(!stateData['description']) {
              stateData['description'] = ''
            }
            setEditState(stateData)
                }
       
    })
     
    }
  }, [pcaId, defaultData])

  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === 'checkbox') {
      value = data.checked
    }
    setEditState(prevState => ({...prevState,  [name]: value }))
  }

  const handleOnClickEdit = () => {
    setEditable(true)
  }

 const handleSubmit = () => {
    const codeTableData: PCA = editState
    setInProgress(true)
    try {
      savePCA(codeTableData, (result: any) => {
      
        const routePrefix = `/admin/pca`
        if ([201, 200].includes(result.status)) {
         
          dimDelayNavigate(setDimmerActive, () => {navigate(routePrefix)})
      
      }
        else {
          console.log(result.message);
        }
      }).then(() => {
        console.log("saved")
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setInProgress(false)
      }
      );
    } catch (error) {
      console.log(error);
    } finally {
      // setSaveValue(false)
    }
  }

    return (
          <div>   
        <Segment raised>
        <Label as='a' color='red' ribbon>
          PCA Details
        </Label>
      
        <Form onSubmit={handleSubmit} >
          <Form.Group widths='equal'>
            <Form.Input fluid name="name" label='PCA Name' placeholder='PCA Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.name}
              />
          </Form.Group>
          <Form.TextArea name="description" label='Description' placeholder='Description about where the PCA is used'
           onChange={handleChange}
           value={editState.description}  readOnly={!isEditable}
           />
           
          <Form.Group inline>
            <label>{ isEditable? "Enabled" : editState.isActive ? "Enabled: Yes" : "Enabled: No"}</label>
            {isEditable &&
            <Form.Checkbox
              name="isActive"
              defaultChecked={editState.isActive === true}
              toggle
              readOnly={!isEditable}
              
              onChange={handleChange}
              />
            }
            <label>{ isEditable? "Default" : editState.isDefault ? "Default: Yes" : "Default: No"}</label>
            {isEditable &&
            <Form.Checkbox
                          name="isDefault"
              defaultChecked={editState.isDefault === true}
              toggle
              readOnly={!isEditable}
             
              onChange={handleChange}
              />
            }
        
          </Form.Group>
          {isEditable &&
          <><Form.Button color="blue" disabled={inProgress} icon>
              <Icon name='save' />
           Save</Form.Button>
          
           </>
          }
           {!isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="edit" /> Edit
            </Form.Button>
          )}
        </Form>
        <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>

        <Dimmer active={dimmerActive} page>
          <Header as="h2" icon inverted>
            <Icon name="check" color="green" />
            Saved successfully!
            <Header.Subheader>Redirecting you to list page</Header.Subheader>
          </Header>
        </Dimmer>
        </Segment>
        <Divider />
        
        <PCAProjectComponents pcaId={pcaId} codeTable={editState}/>
      </div>
    )
}

export default PCAEditComponent;