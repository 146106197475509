import { useState } from "react";
import { Routes, Route, Navigate } from "react-router";
import { Grid, GridColumn, Sidebar, Segment } from "semantic-ui-react";
import SidebarMenu from "../components/Sidebar/SideMenu";
import { useAuth } from "../hooks/useAuth";
import AdminPage from "../pages/adminUsers/admin.page";
import CodeTablePage from "../pages/codetable/codetable.page";
import FundingEligibilityPage from "../pages/funding-eligibility/funding-eligibility.page";
import FundingProgramPage from "../pages/funding-programs/funding-program.page";
import ProjectComponentPage from "../pages/pca/pca-component.page";
import PCACriteriaPage from "../pages/pca/pca-criteria.page";
import PCAQuestionPage from "../pages/pca/pca-question.page";
import PCASubCriteriaPage from "../pages/pca/pca-sub-criteria.page";
import PCAPage from "../pages/pca/pca.page";
import ContentEditComponent from "../pages/ui-content/components/content-edit.component";
import ContentPage from "../pages/ui-content/content.page";
import UsersPage from "../pages/users/users.page";

function AdminLayout() {
  const { user } = useAuth();
  const [items, setItems] = useState(

    user?.userRoles?.split(',')?.includes?.('superadmin')
      ?
      [
        ["Home", "/admin"],
        ["Users", "/admin/users"],
        ["Code Reference", "/admin/codetable"],
        ["PCA", "/admin/pca"],
        ["Funding Program", "/admin/funding-program"],
        ["Home Cards", "/admin/content/home_cards"],
        ["Settings", "/admin/settings"],
      ] :

      user?.userRoles?.split(',')?.includes?.('admin')
        ?
        [

          ["PCA", "/admin/pca"],

        ] :
        [

        ]
  )

  if (!user) {
    return <Navigate to="/admin/login" />;
  }



  return (
    <>
      {/* <Segment attached="top" borderless fixed="top">
            <TopHeader>
            </TopHeader>
        </Segment> */}

      <Segment attached="bottom">
        {/* <div className="ui bottom attached "> */}
        <Grid divided id="appgrid1">
          <GridColumn className="left" style={{ flex: " 0 0 260px" }}>
            <Sidebar visible>
              <SidebarMenu

                items={items}


              />
            </Sidebar>
          </GridColumn>
          <GridColumn style={{ flex: "1" }}>
            <Segment>
              <Routes>
                <Route path="/codetable/*" element={<CodeTablePage />} />
                <Route
                  path="/pca/:pcaId/project-component/:projectComponentId/criteria/:criteriaId/sub-criteria/:subCriteriaId/question/*"
                  element={<PCAQuestionPage />}
                />
                <Route
                  path="/pca/:pcaId/project-component/:projectComponentId/criteria/:criteriaId/sub-criteria/*"
                  element={<PCASubCriteriaPage />}
                />
                <Route
                  path="/pca/:pcaId/project-component/:projectComponentId/criteria/*"
                  element={<PCACriteriaPage />}
                />
                <Route
                  path="/pca/:pcaId/project-component/*"
                  element={<ProjectComponentPage />}
                />
                <Route path="/pca/*" element={<PCAPage />} />
                <Route
                  path="/funding-program/*"
                  element={<FundingProgramPage />}
                />
                <Route path="/users/*" element={<UsersPage />} />
                <Route path="/admin-users/*" element={<AdminPage />} />
                <Route
                  path="/funding-eligibility/*"
                  element={<FundingEligibilityPage />}
                />
                <Route
                  path="/content*"
                  element={<ContentEditComponent />}
                />
              </Routes>
            </Segment>
          </GridColumn>
        </Grid>
        {/* </div> */}
      </Segment>

    </>
  );
}
export default AdminLayout;
