import Axios from 'axios';
import { sortData } from '../../components/data-table/data-table';
import { CODETABLE_TYPES, SelectOption } from '../../models/codetable';
import { buildAPIURL, getAxiosDefaultOptions } from '../../utils/common-utils';



export async function getCodeTableList(callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL('/code-table?excludeValues&includeInactive'), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function getCodeTableListByTypeCodes(typeCodes: CODETABLE_TYPES[],callback: any) {
    const typeCodesParam = typeCodes ? `&typeCodes=${typeCodes.join(',')}` : ''
 
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/code-table?excludeValues=false${typeCodesParam}`), options).then(async (results) => {
        if(!results) {
            return;
          }
        
          const codeTableOptions: {[key:string]: SelectOption[]} = {}
          results.data.data.forEach(codeTable => {
            if(codeTable.values && codeTable.values.length > 0) {
                const selectOptions:SelectOption[] = 
                sortData(codeTable.values, 'displaySequence', true)
                .map(codeValueItem => {
                  return {
                    text: codeValueItem.name,
                    value: codeValueItem.id
                  }
                })
                codeTableOptions[codeTable.typeCode] = selectOptions;
              }
          })
        //   return codeTableOptions;
        callback(codeTableOptions);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function getCodeTableDetail(id:number,callback: any) {
    const options = getAxiosDefaultOptions();

    await Axios.get(buildAPIURL(`/code-table/${id}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export interface CodeTable {
    name: string;
    typeCode: string;
    id?: number;
    description?: string;
    isActive: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export async function saveCodeTable(data: CodeTable, callback: any) {
    const options = getAxiosDefaultOptions();
    const requestMethod = data.id ? Axios.put : Axios.post
    const pathSuffix = data.id ? `/${data.id}` : '';
    // const payload = { codeTable: data , codeTableValue: []}
    await requestMethod(buildAPIURL(`/code-table${pathSuffix}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function deleteCodeTable(rowId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = rowId ? `/${rowId}` : '';
    
    await Axios.delete (buildAPIURL(`/code-table${pathSuffix}`),options
       ).then(async (response) => {
      
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function deleteCodeTableValues(rowId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = rowId ? `/${rowId}` : '';
      await Axios.delete(buildAPIURL(`/code-table-values${pathSuffix}`),options
       ).then(async (response) => {
      
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
        
};