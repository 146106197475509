import Axios from 'axios';
import { buildAPIURL, getDefaultHeaders, ifnFetch } from '../../utils/common-utils';


export async function getUsersList(roletype:string,callback: any,) {
    const headers = getDefaultHeaders()
    const requestMethod =  Axios.get
    await requestMethod(buildAPIURL(`/user?roles=${roletype}`), {
        headers: headers
    }).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
;

export async function getUser(id:number,callback: any) {
    const headers = getDefaultHeaders()
    const requestMethod =  Axios.get
    await requestMethod(buildAPIURL(`/user/${id}`), {
        headers: headers
    }).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};


export interface User {
    firstName: string;
    lastName: string;
    typeOfEntityId:number;
    affiliation: string;
    id?: number;
    positionId: number;
    email:string;
    password?:number;
    userType:number;
    status:boolean;
    createdAt?: Date;
    updatedAt?: Date;
}



export async function saveUsers(data: User, callback: any) {
    const headers = getDefaultHeaders()
    const requestMethod = data.id ? Axios.patch : Axios.post
    const pathSuffix = data.id ? `/${data.id}` : '';
    // const payload = { codeTable: data , codeTableValue: []}
    await requestMethod(buildAPIURL(`/user${pathSuffix}`),
        data, {
        headers: headers
    }).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};













