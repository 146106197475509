import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import DataTable from "../../../components/data-table/data-table";
import { getFundingProgramList } from "../funding-program.service";

const FundingProgramList = () => {
  const defaultRow = {
    data: [],
  };

  const emptyRow = useMemo(
    () => ({ id: undefined, name: "", isActive: true, description: "" }),
    []
  );

  const navigate = useNavigate();

  const [tableData, setTableDataState] = useState(defaultRow);
  const colDefinitions = [
    { name: "ID", field: "id", type: "number" },
    { name: "Program Name", field: "name", type: "text" },
    { name: "Eligible Recipients", field: "eligibleRecipients", type: "text" },
    { name: "Purpose", field: "purpose", type: "text" , width: "4"},
    {
      name: "Funding Amount ",
      field: "fundingAmountText",
      type: "text",
    },
    {
      name: "Program Agency",
      field: "programAgency",
      type: "text",
    },
    {
      name: "Status",
      field: "status",
      type: "text",
    },
    // {
    //   name: "Link",
    //   field: "link",
    //   type: "text",
    //   width: "2"
    // },
    { name: "Enabled", field: "isActive", type: "boolean" },
  ];
  const actionDefinitions = [
    {
      name: "Edit",
      icon: "edit",
      onClick: (evt, rowIndex, rowId) => {
        navigate(`/admin/funding-program/${rowId}`);
      },
    },
  ];
  const tableActionDefinitions = [
    {
      name: "Add Row",
      icon: "add",
      route: "/admin/funding-program/new",
      color: "green",
      placeIn: "header",
    },
  ];

  useEffect(() => {
    getFundingProgramList((result) => {
      if (result.data) {
        setTableDataState({ data: result.data });
      }
    })
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Divider hidden />

      <DataTable
        colDefinitions={colDefinitions}
        rowData={tableData.data}
        name=""
        rowActions={actionDefinitions}
        tableActions={tableActionDefinitions}
        emptyRow={emptyRow}
      />
    </div>
  );
};

export default FundingProgramList;
