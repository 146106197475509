import axios, { AxiosRequestConfig } from "axios";
import ls from 'localstorage-slim';
import { LocalStorageConfig } from "localstorage-slim/dist/types";
import Constants from "./constants";

export  function delay(time:number) {
    return new Promise(resolve => setTimeout(resolve, time));
}
  

export function getAPIBaseURI() {

    const BASE_API_URL = process.env.REACT_APP_API_URL;

    return BASE_API_URL;
}
export function buildAPIURL(apiPath: string) {
    const baseURL = getAPIBaseURI();
    let prefix = '';
    if (!apiPath?.startsWith('/')) {
        prefix = '/'
    }

    const apiUrl = `${baseURL}${prefix}${apiPath}`

    return apiUrl
}

export function dimDelayNavigate(setDimmerStateFn:any, callbackFn: any, delayMs:number = 1000) {
    setDimmerStateFn(true);
    delay(delayMs).then(() => {
        if(callbackFn) {
            callbackFn()
        }
        setDimmerStateFn(false)
    });
}

export function getDefaultLocalStorageOptions(encrypt?: boolean) {
    const options:LocalStorageConfig  = {encrypt: true, ttl: 12 * Constants.HOUR_IN_SECS};
    if(encrypt) {
        options.secret = Number.isInteger(+process.env.REACT_APP_SECRET) ?  +process.env.REACT_APP_SECRET : process.env.REACT_APP_SECRET;
      }

    return options
}

export function getLocalStorageSecureItem() {
    const appEnv = process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV : ''
    const lsKey = `ifncms-${appEnv}-u`
    const options = getDefaultLocalStorageOptions(true);
    const storedItem =  ls.get(lsKey, options);
    return storedItem
}

export function getDefaultHeaders() {
    const headers = {
        'Content-Type': 'application/json',
    };
    if(axios.defaults.headers.common['Authorization']) {
        console.log('Using available headers', axios.defaults.headers.common['Authorization'])
        headers['Authorization'] = axios.defaults.headers.common['Authorization']
    } else {
        
        const user  = getLocalStorageSecureItem();
        console.log('Using local storage', user)
        if(user && user['token']) {
            const token = user['token'];
            headers['Authorization'] = `Bearer ${token}`
        }
    }
    return headers;
}
export function getAxiosDefaultOptions():AxiosRequestConfig<any> {
    const options = {
        headers: getDefaultHeaders()
    } 
    return options;
}

export function ifnFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
    const headers = getDefaultHeaders();
    init = init || {};
    init.headers = init.headers || headers;
    if(!init.headers['Authorization'] && headers['Authorization']) {
        init.headers['Authorization'] = headers['Authorization']
    }

    return fetch(input, init);
}
