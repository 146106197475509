import { Header, } from 'semantic-ui-react'
import { Route, Routes } from 'react-router';

import AdminList from './components/admin-list-component';
import AdminEditComponent from './components/admin-edit-component';
const AdminPage = () => {


 const GetPageRoutes = function() {
        return (
          <Routes>
            <Route path="/" element={<AdminList />}/> 
            <Route path=":id" element={<AdminEditComponent />} />
            <Route path="new" element={<AdminEditComponent />} />
          
          </Routes>
        )
 } 

 return (
   <div>
    <Header as='h2' className='left' textAlign='left'>
        <Header.Content>
       Admin Users
            <Header.Subheader>
              Manage the registered users here. You can also create new users here.
            </Header.Subheader>
        </Header.Content>
    </Header>
    <GetPageRoutes />
   </div>
 );
};

export default AdminPage;