import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import DataTable from "../../../components/data-table/data-table";
import { useAuth } from "../../../hooks/useAuth";
import { getUsersList } from "../users.service";
const UsersList = (props:any) => {
  const defaultRow = {
    data: [{}],
  };
  const { user } = useAuth()
  const navigate = useNavigate();
  const roletype="user"
  const [tableData, setTableDataState] = useState(defaultRow);
  const colDefinitions = [
    { name: "ID", field: "id", type: "number" },
    { name: "First Name", field: "firstName", type: "text" },
    { name: "Last Name", field: "lastName", type: "text" },
    { name: "Organization", field: "organization", type: "text" },
    { name: "Position", field: "position", type: "text" },
    { name: "Email", field: "email", type: "text" },
  ];
  const actionDefinitions = [
    {
      name: "Edit",
      icon: "edit",
      onClick: (evt, rowIndex, rowId) => {
        navigate(`/admin/users/${rowId}`);
      },
    },
  ];
 
  const tableActionDefinitions = [
    {
      name: "Add User",
      icon: "add",
      route: "/admin/users/new",
      color: "green",
      placeIn: "header",
    },
    {
      name: "Export CSV",
      icon: "download",
      color: "green",
      placeIn: "header",
      CSVLink: 'tableData.data',
      type:'csvdownload',
      options: {exportFileName: 'users-list.csv'}
    },
   
  ];
  useEffect(() => {
   getUsersList(roletype,(result) => {
      if (result.data) {
       
        setTableDataState({ data: result.data });
      }
    })
   
  }, []);
  return (  
      <div style={{ height: "100vh" }}>
        <Divider hidden />
         <DataTable
          colDefinitions={colDefinitions}
          rowData={tableData.data}
          rowActions={actionDefinitions}
          name=""
          tableActions={tableActionDefinitions}
          
        />
        
      </div>
  );
};
export default UsersList;