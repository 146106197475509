import { Table, Button, Icon, Form, Checkbox, Message, Dropdown, Popup } from "semantic-ui-react";
import PropTypes from 'prop-types'
import { useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

const DataTable = ({ colDefinitions, name, rowData, onFieldChange, onClickAdd, handleChange,
	onClickEdit, onFormSubmit, allowEdit, handleStateUpdate, rowActions,
	tableActions, compact, emptyRow, lookupCodeTableData, reorderOn }) => {
	const rightColSpan = colDefinitions.length
	const [saveInProgress, setSaveInProgress] = useState(false)

	// let emptyRow:any = { id: undefined, "editable": true };
	// colDefinitions.forEach(colDef => {
	//     emptyRow[colDef.fi]

	// })
	if (!emptyRow) {
		// console.log('DataTable', "Empty row wasn't passed, assuming default")
		emptyRow = { id: undefined, 'name': '', 'typeCode': '', 'isActive': true, "editable": true };
	}

	const localRowActions = []
	if (reorderOn) {
		const onReorderClick = (evt, rowIndex, rowId, direction: 'up' | 'down') => {
			handleStateUpdate(prevState => {
				const data = [...prevState.data];
				const moveByPos = direction === 'up' ? -1 : 1;
				const moveFrom = rowIndex;
				const moveTo = direction === 'up' ? Math.max(0, rowIndex + moveByPos) : Math.min(data.length - 1, rowIndex + moveByPos);

				let temp = data[moveTo];
				data[moveTo] = data[moveFrom];
				data[moveFrom] = temp;

				return { data };
			});
		}

		const reorderActions = [
			{ name: 'Move Up', icon: 'arrow up', type: 'moveup', color: 'blue', onClick: (evt, rowIndex, rowId) => onReorderClick(evt, rowIndex, rowId, 'up') },
			{ name: 'Move Down', icon: 'arrow down', type: 'movedown', color: 'blue', onClick: (evt, rowIndex, rowId) => onReorderClick(evt, rowIndex, rowId, 'down') }
		];

		localRowActions.push(...reorderActions)
	}
	localRowActions.push(...(rowActions || []))

	// const emptyRow = { id: undefined, 'name': '', 'typeCode': '', 'isActive': true, "editable": true };

	const handleInputKeyPress = (event, rowIndex, rowId, colIndex) => {
		if (event.key === 'Enter') {
			event.preventDefault()
			const field = colDefinitions[0].field
			if (rowData && rowData[rowIndex][field] && rowData[rowIndex][field].length > 0) {
				// Current row has data, let's check if next row is empty.
				const lastRow = rowData.length - 1;
				let addRow = false;
				if (rowData[lastRow][field] && rowData[lastRow][field].length > 0) {
					addRow = true;
				}
				if (addRow) {
					addEmptyRow()
				}
			}
		}
	}
	const addEmptyRow = () => {
		handleStateUpdate(prevState => ({
			data: [...prevState.data, emptyRow]
		}));
	}
	const handleOnClickAdd = (evt) => {
		addEmptyRow()

	}
	const handleOnClickEdit = (evt, rowIndex) => {
		handleStateUpdate(prevState => ({
			data: prevState.data.map((row, key) => (key === rowIndex ? { ...row, editable: true } : row))
		}));
	}
	const handleFieldChange = (inputFieldId, inputFieldValue) => {

		const keyParts: string[] = inputFieldValue.id.split('.')
		const rowIndex = +keyParts[0].replace('r', '')
		const fieldValue = inputFieldValue.type === 'checkbox' ? inputFieldValue.checked : inputFieldValue.value
		handleStateUpdate(prevState => {
			return {
				data: prevState.data.map((row, key) => {
					return key === rowIndex ? { ...row, [inputFieldValue.name]: fieldValue } : row;
				})
			}
		});
	}

	const getFieldData = (row, field, rowFields) => {

		let columnAccessor = field.split(".");
		let columnValue;
		if (columnAccessor?.length === 1) {
			if (rowFields.indexOf(field) >= 0) {
				columnValue = row[field]
			}
		} else if (columnAccessor?.length > 1) {
			let valueObject = { ...row };
			columnAccessor?.map((objectAccessor: number) => {
				if (valueObject?.hasOwnProperty(objectAccessor)) {
					valueObject = valueObject[objectAccessor]
				}
			});
			columnValue = valueObject
		}
		return columnValue || '';
	}

	const onSaveClick = () => {
		// setSaveInProgress(true)
		onFormSubmit()
	}

	onClickAdd = onClickAdd || handleOnClickAdd
	onClickEdit = onClickEdit || handleOnClickEdit
	onFieldChange = onFieldChange || handleFieldChange

	tableActions = tableActions || []
	const headers = [
		{ label: "Id", key: "id" },
		{ label: "First Name", key: "firstName" },
		{ label: "Last Name", key: "lastName" },
		{ label: "Organization", key: "organization" },
		{ label: "Position", key: "position" },
		{ label: "Email", key: "email" },
		{ label: "Heard Through", key: "heardthrough" },
		{ label: "Subscribe To Email", key: "subscribeToEmail" },
		{ label: "Status", key: "status" },
		{ label: "createdAt", key: "createdAt" },
	];




	return (
		<Form>
			<Table celled compact>
				<Table.Header fullWidth>
					<Table.Row>
						<Table.HeaderCell colSpan='2'>{name}</Table.HeaderCell>
						<Table.HeaderCell colSpan={rightColSpan}>
							{tableActions.filter(tableAction => tableAction.placeIn === 'header').map((action, buttonIndex: any, colDef) => {

								if (action.route) {
									return (
										<Button key={buttonIndex} floated="right"
											color={action.color}
											as={Link}
											to={action.route}
										>
											<Icon name={action.icon} />{action.name}
										</Button>
									)
								} else {
									if (action.type === 'csvdownload') {
										return (
											<Button key={buttonIndex} floated="right"
												color={action.color}
												onClick={action.onClick}
												type={CSVLink}>
												<CSVLink data={rowData || []} headers={headers} filename={action.options.exportFileName} style={{ color: "#fff" }}>
													<Icon name={action.icon} /> {action.name}</CSVLink>
											</Button>
										)
									} else {
										const clickHandler = action.type === 'add inline' ? onClickAdd : action.onClick;

										return (
											<Button key={buttonIndex} floated="right"
												color={action.color}
												onClick={clickHandler}>
												<Icon name={action.icon} /> {action.name}
											</Button>
										)
									}
								}
							}
							)
							}

						</Table.HeaderCell>

					</Table.Row>
					<Table.Row>
						{colDefinitions.map((colDef, index: any) => {
							if (colDef.width) {
								return <Table.HeaderCell width={colDef.width} collapsing={colDef.collapsing || false} key={index}>{colDef.name}</Table.HeaderCell>
							} else {
								return <Table.HeaderCell collapsing={colDef.collapsing || false} key={index}>{colDef.name}</Table.HeaderCell>
							}
						})
						}
						<Table.HeaderCell>Actions</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				{(!rowData || rowData.length === 0) &&
					<Table.Body>
						<Table.Row key={`r0.i-`}>
							<Table.Cell key='0' colSpan={rightColSpan + 2} textAlign="center">
								<Message warning visible info floating>
									<Message.Header>No records found.</Message.Header>
									<p>You can add some records using Add button.</p>
								</Message>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				}
				{rowData && rowData.length > 0 &&
					<Table.Body>
						{rowData.map((row, rowIndex: number) => {

							const rowFields = Object.keys(row);
							const rowId = row['id'] || '-';
							const rowKey = `r${rowIndex}.i${rowId}`
							const isRowEditable = allowEdit && ((rowFields.indexOf('editable') >= 0 && row['editable'] === true));

							return (
								<Table.Row key={rowKey}>
									{
										colDefinitions.map((colDef, colIndex: any) => {
											let columnValue = undefined;
											const columnKey = `${rowKey}.f${colDef.field}`
											columnValue = getFieldData(row, colDef.field, rowFields)
											// if (rowFields.indexOf(colDef.field) >= 0) {
											//     columnValue = row[colDef.field]
											// }
											if (colDef.type === 'toggle') {
												return (
													<Table.Cell key={colIndex}>
														<Checkbox name={colDef.field} id={columnKey} toggle
															defaultChecked={columnValue === true}
															onChange={onFieldChange}
														/>
													</Table.Cell>
												)
											}
											else if (colDef.type === 'dropdown') {
												return (
													<Table.Cell key={colIndex}>
														<Form.Select fluid name={colDef.field} placeholder={colDef.name} id={columnKey}
															required
															options={lookupCodeTableData[colDef.lookupKey]}
															value={columnValue}
															onChange={onFieldChange}
														/>
													</Table.Cell>
												)
											}
											else if (colDef.type === 'array') {
												return (
													<Table.Cell key={`${rowKey}-ccard-${colIndex}`}>
														<ul>
															{row?.data?.items?.map((item) => (
																<li key={`${rowKey}-ccardt-${colIndex}`}>{item.title}</li>
															))}
														</ul>
													</Table.Cell>
												)
											}
											else {
												if (isRowEditable) {
													return (
														<Table.Cell key={colIndex}>
															<Form.Input fluid name={colDef.field} id={columnKey}
																placeholder={`Enter ${colDef.name}`}
																//   required 
																onChange={onFieldChange}
																value={columnValue}
																onKeyPress={(event) => handleInputKeyPress(event, rowIndex, row['id'], colIndex)}
															/>
														</Table.Cell>
													)
												} else {
													if (colDef.type === 'boolean') {
														columnValue = columnValue === true ? 'Yes' : 'No'
													}
													return (<Table.Cell key={colIndex}>{columnValue}</Table.Cell>)
												}
											}
										})
									}
									<Table.Cell>
										{
											localRowActions && localRowActions.map((action, actionIndex: number) => {
												const clickHandler = action.type === 'add inline' ? onClickAdd : action.onClick;

												if (action.type === 'edit inline') {
													if (isRowEditable) {
														return null
													} else {
														return (
															<Icon key={`${rowKey}-ra-${actionIndex}`} name='edit' color={action.color} link onClick={(evt) => onClickEdit(evt, rowIndex, row['id'])} />
														)
													}
												} else if (['moveup', 'movedown'].includes(action.type)) {
													return (
														<Popup
															trigger={<Icon key={`${rowKey}-ra-${actionIndex}`} data-content="test content" name={action.icon} color={action.color} link disabled={(action.type === 'moveup' && rowIndex === 0) || (action.type === 'movedown' && (rowIndex + 1) === rowData.length)} onClick={(evt) => clickHandler(evt, rowIndex, row['id'])} />}
															content={action.name}
															size='mini'
															key={`${rowKey}-rap-${actionIndex}`}
														/>
													)
												} else {
													return (
														<Icon key={`${rowKey}-ra-${actionIndex}`} name={action.icon} color={action.color} link onClick={(evt) => clickHandler(evt, rowIndex, row['id'])} />
													)
												}
											})
										}
									</Table.Cell>
								</Table.Row>
							)

						})
						}
					</Table.Body>
				}


				<Table.Footer fullWidth>
					{allowEdit &&
						<Table.Row>
							<Table.HeaderCell colSpan='5'>
								<Button
									//   floated='right'
									icon
									// labelPosition='left'
									primary
									// size='small'
									onClick={onSaveClick}
									loading={saveInProgress}
								>
									<Icon name='save' />Save
								</Button>
								{/* <Button key={buttonIndex} floated="right" 
                                        color={action.color}
                                        as={Link}
                                        to={action.route}
                                        >
                                        <Icon name={action.icon}  />{action.name}
                                    </Button> */}
							</Table.HeaderCell>
						</Table.Row>
					}
				</Table.Footer>
			</Table>
		</Form>
	)
}
DataTable.propTypes = {
	name: PropTypes.string,
	colDefinitions: PropTypes.arrayOf(PropTypes.shape({
		field: PropTypes.string,
		name: PropTypes.string,
		type: PropTypes.oneOf(['text', 'toggle', 'date', 'boolean', 'dropdown', 'number']),
		required: PropTypes.bool,
		collapsing: PropTypes.bool
	}),
	),
	rowData: PropTypes.arrayOf(PropTypes.object),
	allowEdit: PropTypes.bool,
	onFieldChange: PropTypes.func,
	handleChange: PropTypes.func,
	reorderOn: PropTypes.string,
	onClickAdd: PropTypes.func,
	onClickEdit: PropTypes.func,
	onFormSubmit: PropTypes.func,
	handleStateUpdate: PropTypes.func,
	compact: PropTypes.bool,
	rowActions: PropTypes.arrayOf(PropTypes.shape({
		icon: PropTypes.string,
		color: PropTypes.string,
		name: PropTypes.string,
		type: PropTypes.oneOf(['edit', 'delete', 'add', 'add inline', 'moveup', 'movedown', 'view', 'viewchild', 'edit inline']),
		onClick: PropTypes.func,
		route: PropTypes.string,
	})),
	tableActions: PropTypes.arrayOf(PropTypes.shape({
		icon: PropTypes.string,
		color: PropTypes.string,
		name: PropTypes.string,
		type: PropTypes.oneOf(['add', 'save', 'add inline', 'csvdownload']),
		onClick: PropTypes.func,
		route: PropTypes.string,
		placeIn: PropTypes.oneOf(['header', 'footer']),
		floated: PropTypes.oneOf(['left', 'right']),
	})),
	emptyRow: PropTypes.object,
	lookupCodeTableData: PropTypes.object
}

export function sortData(data: any[], sortByField: string, isAscending: boolean) {
	function compare(a, b) {
		if (a[sortByField] < b[sortByField]) {
			return isAscending ? -1 : 1;
		}
		if (a[sortByField] > b[sortByField]) {
			return isAscending ? 1 : -1;
		}
		return 0;
	}

	return data.sort(compare);
}

export default DataTable;