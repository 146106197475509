import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useAuth } from '../../hooks/useAuth';

const SignOutComponent = () => {
    const auth = useAuth()

    useEffect(() => {
       auth.logout();
    }) 

    return (
    <Navigate to="/admin/login" replace />
    )
}

export default SignOutComponent
// export default {}