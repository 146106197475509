import Axios from 'axios';
import { SelectOption } from '../../models/codetable';
import { buildAPIURL, getAxiosDefaultOptions } from '../../utils/common-utils';


export async function getFundingProgramList(callback: any) {
    const options = getAxiosDefaultOptions();

    const requestMethod =  Axios.get
    await requestMethod(buildAPIURL(`/funding-program`), options)
        .then((results:any) => {
            if(!results) {
                return;
              }
                const selectOptions:SelectOption[] = 
                results.data
                .map(rowItem => {
                    return {
                    text: rowItem.name,
                    value: rowItem.id
                    }
                })
            
                callback(selectOptions);
        })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
}

export async function getFundingEligibilityList(callback: any) {
    const options = getAxiosDefaultOptions();

    const requestMethod =  Axios.get
    await requestMethod(buildAPIURL(`/funding-eligibility?includeInactive&skipDistinct=1`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getFundingEligibilityDetail(id:number,callback: any) {
    const options = getAxiosDefaultOptions();
    const requestMethod =  Axios.get
    await requestMethod(buildAPIURL(`/funding-program/${id}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export interface FundingEligibilityMap {
    name: string;
    id?: number;
    typeOfProjectId?: number;
    projectCategoryId?: number;
    implementingEntityId: number;
    eligibleActivityId: number;
    keywordId: number;
    locationId:number;
    isActive: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}


export async function saveFundingEligibilityEntry(data: FundingEligibilityMap, callback: any) {
    const options = getAxiosDefaultOptions();
    const requestMethod = data.id ? Axios.patch : Axios.post
    const pathSuffix = data.id ? `/${data.id}` : '';
    await requestMethod(buildAPIURL(`/funding-eligibility${pathSuffix}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};


