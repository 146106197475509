import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Dimmer, Divider, Header, Icon } from "semantic-ui-react";
import DataTable from "../../../components/data-table/data-table";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { deletePca, getPCAList } from "../pca.service";

const PCAList = () => {
  const defaultRow = {
    data: [{}],
  };

  const emptyRow = useMemo(
    () => ({ id: undefined, name: "", isActive: true, description: "" }),
    []
  );

  const navigate = useNavigate();
  const [dimmerActive, setDimmerActive] = useState(false)
  const [dimmerFailed, setDimmerFailed] = useState(false)
  const [tableData, setTableDataState] = useState(defaultRow);
  const colDefinitions = [
    { name: "ID", field: "id", type: "number" },
    { name: "PCA Name", field: "name", type: "text" },
    { name: "Description", field: "description", type: "text" },
    { name: "Enabled", field: "isActive", type: "boolean" },
    { name: "Default", field: "isDefault", type: "boolean" },
  ];
  const actionDefinitions = [
    {
      name: "Edit",
      icon: "edit",
      onClick: (evt, rowIndex, rowId) => {
        navigate(`/admin/pca/${rowId}`);
      },
    },
    {
      name: "Delete",
      icon: "trash",
      onClick: (evt, rowIndex, rowId) => {
        handleOnClickDelete(rowId)
      },
    },
  ];

  const handleOnClickDelete = (rowId: any) => {

    deletePca(rowId, (result: any) => {

      if (result.data.statusCode == 202) {

        dimDelayNavigate(setDimmerActive, () => result?.data?.message)
        window.location.reload()
      }
      else {
        dimDelayNavigate(setDimmerFailed, () => result?.data?.message)
      }
    })
  };
  const tableActionDefinitions = [
    {
      name: "Add Row",
      icon: "add",
      route: "/admin/pca/new",
      color: "green",
      placeIn: "header",
    },
  ];

  useEffect(() => {
    getPCAList((result) => {

      if (result.data) {
       
        setTableDataState({ data: result.data });
      }

    })
 }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Divider hidden />

      <DataTable
        colDefinitions={colDefinitions}
        rowData={tableData.data}
        name=""
        rowActions={actionDefinitions}
        tableActions={tableActionDefinitions}
        emptyRow={emptyRow}
      />
      <Dimmer active={dimmerActive} page>
        <Header as='h2' icon inverted>
          <Icon name='check' color="green" />
          Deleted Successfully!
        </Header>
      </Dimmer>
      <Dimmer active={dimmerFailed} page>
        <Header as='h2' icon inverted>
          <Icon name='check' color="red" />
          All referenced data must be deleted to delete this record!
        </Header>
      </Dimmer>
    </div>
  );
};

export default PCAList;
function setDimmerActive(setDimmerActive: any) {
  throw new Error("Function not implemented.");
}

