import { Header } from "semantic-ui-react";
import { Route, Routes } from "react-router";
import FundingProgramList from "./components/funding-program-list.component";
import FundingProgramEditComponent from "./components/funding-program-edit.component";

const FundingProgramPage = () => {
  const GetPageRoutes = function () {
    return (
      <Routes>
        <Route path=":id" element={<FundingProgramEditComponent />} />
        <Route path="new" element={<FundingProgramEditComponent />} />
        <Route path="/" element={<FundingProgramList />} />
      </Routes>
    );
  };
  return (
    <div>
      <Header as="h2" className="left" textAlign="left">
        <Header.Content>
          Funding Program
          <Header.Subheader>
            <p> Manage Funding Programs</p>
          </Header.Subheader>
        </Header.Content>
      </Header>
      <GetPageRoutes />
    </div>
  );
};

export default FundingProgramPage;
