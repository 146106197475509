import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Icon, Label, Segment, Dimmer, Header, FormGroup, Divider, } from "semantic-ui-react";
import { Content, CONTENT_TYPES } from "../../../models/content";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { saveContent, getContentDetail } from "../content.service";
const ContentEditComponent = () => {
  const params = useParams()
  // const idParam = params?.id
  const contentId = params?.id || 'home_cards'
  const defaultData = useMemo(() => {
    return { title: '', displayTitle: '', typeCode: 'home_cards', isActive: true, isDefault: false, data: {items: [
    {
      title: '',
      display_title: '',

    },
    {
      title: '',
      display_title: '',
    },
    {
      title: '',
      display_title: '',
    }
  ]} }}, []);

  const [dimmerActive, setDimmerActive] = useState(false);
  const navigate = useNavigate()
  const [isEditable, setEditable] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [isError, setIsError] = useState(false);
  const [content, setContent] = useState<Content>(defaultData)
  

  useEffect(() => {
    if(!contentId) {
      return;
    }

    getContentDetail(contentId,(result)=>{
      if (result.data) {
        setEditable(true)
        setContent(result.data)
      }
  })    
   

  }, [contentId, defaultData])


  const handleOnClickEdit = () => {
    setEditable(true)
  }

  const handleSubmit = () => {
    // tableData.typeCode = CONTENT_TYPES.HOME_CARDS;
    const payload = { ...content,
      typeCode: CONTENT_TYPES.HOME_CARDS,
      title: 'Home Cards'
    }

    try {

      saveContent(payload, (result: any) => {
        const routePrefix = `/content${result.data}`
        if ([201, 200].includes(result.status)) {
          dimDelayNavigate(setDimmerActive, () => { navigate(routePrefix) })
        }
        else {
          console.log(result.message);
        }
      }).then((data) => {
        console.log("saved")
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setInProgress(false)
      }
      );
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleChangeInput = async (name, fieldValue, rowIndex) => {
    setContent(prevState =>{
      return {
        ...prevState,
          data: { items: 
            prevState.data.items.map((row, key) => {
              return key === rowIndex ? { ...row, [name]: fieldValue } : row;
          })
        }
      }
    });
    // console.log('contentChange', content)
  };

  return (
    <div>
      <Segment raised>
        <Label as='a' color='red' ribbon>
          Home Card Details
        </Label>

        <Form onSubmit={handleSubmit} >
          {content && content.data?.items?.map((items, index) => {


            return (
              <>
                <h4>Card {index + 1}</h4>
                <Form.Group widths='equal' style={{ width: "495px", flexDirection: "column", }}>
                  <Form.Input fluid name="title" onChange={(e: any) => handleChangeInput(e?.target?.name, e?.target?.value, index)} label='Title' placeholder='Title'
                    required 
                    value={items.title}
                    maxLength={30}
                  />
                </Form.Group>
                <FormGroup style={{ width: "661px" }}>
                  <Form.TextArea width={12} name="display_title" label='Card Content' placeholder='Card Content'
                    onChange={(e: any) => handleChangeInput(e?.target?.name, e?.target?.value, index)}
                    value={items.display_title}  maxLength={300}
                  />
                </FormGroup></>
            )
          })}
          {isError &&
            <div style={{ padding: "8px", color: "red" }}>
              <span >Only allowed for 50 characters</span>
            </div>
          }
          {!isEditable &&
            <><Form.Button color="blue" disabled={inProgress} icon>
              <Icon name='save' />
              Save</Form.Button>

            </>
          }
          {isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="save" /> Save
            </Form.Button>
          )}
        </Form>
        <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>

        <Dimmer active={dimmerActive} page>
          <Header as="h2" icon inverted>
            <Icon name="check" color="green" />
            Saved successfully!
            <Header.Subheader>Redirecting you to list page</Header.Subheader>
          </Header>
        </Dimmer>
      </Segment>
      <Divider />
    </div>
  )
}

export default ContentEditComponent;