import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Icon, Segment, Label, Dimmer, Header } from "semantic-ui-react";
import DataTable, { sortData } from "../../../components/data-table/data-table";
import { CODETABLE_TYPES } from "../../../models/codetable";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { deleteCodeTableValues, getCodeTableListByTypeCodes, saveCodeTable } from "../codetable.service";

const CodeTableValues = (props: any) => {
    const emptyRow = { id: undefined, 'name': '',description:'', 'typeCode': '', 'isActive': true, "editable": true, 'parentId': undefined , keywords: ''};
    const defaultRow = {
        data: [
            emptyRow,
        ]
    }
    const emptyRowDef = useMemo(
        () => (
            { id: undefined, 'name': '',description:'', 'typeCode': '', 'isActive': true, "editable": true, 'parentId': undefined , keywords: '' }
        ), [])

    const codeTable = props.codeTable;
    const defaultColDefinitions = useMemo(() => {
        const initialColDefs = [
            { name: 'Code Value', field: 'name', type: 'text' },
            { name: 'Description', field: 'description', type: 'text' },
            { name: 'Type Key', field: 'typeCode', type: 'text' },
            { name: 'Enabled', field: 'isActive', type: 'toggle', collapsing: true },
        ];
        if(codeTable.typeCode === CODETABLE_TYPES.PROJECT_CATEGORY) {
            initialColDefs.splice(1,0,{ name: 'Keywords', field: 'keywords', type: 'text' })
        }
      
        const customColDefinitions = {
            "project_category": { parentTypeCode: CODETABLE_TYPES.PROJECT_TYPES, colDef: { name: 'Type of Project', field: 'parentId', type: 'dropdown', lookupKey: CODETABLE_TYPES.PROJECT_TYPES } },
          
        }
        let parentTypeCode: CODETABLE_TYPES;
        if (customColDefinitions.hasOwnProperty(codeTable?.typeCode)) {
            initialColDefs.push(customColDefinitions[codeTable.typeCode].colDef)
            parentTypeCode = customColDefinitions[codeTable.typeCode].parentTypeCode
        }
        return { parentTypeCode: parentTypeCode, colDefs: initialColDefs }
    }, [codeTable.typeCode])

    const [dimmerActive, setDimmerActive] = useState(false)
    const [dimmerDeleteFailed, dimmerDeleteActive] = useState(false)
    const [dimmerFailed, setDimmerFailed] = useState(false)
    const [lookupCodeTableData, setLookupCodeTableData] = useState()
    const [tableData, setTableDataState] = useState(defaultRow)

    const [inProgress, setInProgress] = useState(false);

    const [colDefinitions, setColDefinitions] = useState(defaultColDefinitions.colDefs);

    const tableActionDefinitions = [
        { name: 'Add Row', icon: 'add', type: 'add inline', color: 'green', placeIn: 'header' }
       
    ]
    const rowActionDefinitions = [
          {
            name: "Delete",
            icon: "trash",
            color: 'red',
            onClick: (evt, rowIndex, rowId) => {
              handleOnClickDelete(rowId)
            },
            
          },
    ]
    const handleOnClickDelete= (rowId:any) => {
  
        deleteCodeTableValues(rowId, (result: any) => {
        if (result.data.statusCode == 202) {
          
            dimDelayNavigate(dimmerDeleteActive, () =>result?.data?.message )
            window.location.reload()
         } 
         else
         {
          dimDelayNavigate(setDimmerFailed, () =>result?.data?.message )
         }
       })
       };
    const navigate = useNavigate();

    useEffect(() => {
        if (codeTable) {
            if (codeTable.values) {
                const newStateData = codeTable.values.map(codeValue => {
                    codeValue.editable = true
                    return codeValue;
                })
                const sortedData = sortData(newStateData, 'displaySequence', true)
                setTableDataState({ data: sortedData })
            }
        }

        setColDefinitions(defaultColDefinitions.colDefs)
        if (defaultColDefinitions.parentTypeCode) {

            getCodeTableListByTypeCodes(
                [defaultColDefinitions.parentTypeCode],results=>{

                
                // .then((results: any) => {
                    if (!results) {
                        return;
                    }

                    setLookupCodeTableData(results)
                // });
                  }  )
        }

    }, [codeTable, defaultColDefinitions])

    if (!props.codeTableId || (defaultColDefinitions.parentTypeCode && !lookupCodeTableData)) {
        return null;
    }

    const handleFormSubmit = () => {
        const allowedProps = ['id', 'name','description', 'typeCode', 'isActive', 'parentId', 'keywords']
        const codeTableValues = tableData.data
            .filter(codeValue => codeValue.name)
            .map(codeValue => {
                let rowData: { [key: string]: any } = {}
                allowedProps.forEach(propName => rowData[propName] = codeValue[propName])
                return rowData
            })
        codeTable.values = codeTableValues

        setInProgress(true)
        try {
            saveCodeTable(codeTable, (result: any) => {
                if ([201, 200].includes(result.status)) {

                    dimDelayNavigate(setDimmerActive, () => { navigate(`/admin/codetable/${codeTable.id}`) })
                }
                else {
                    console.log(result.message);
                }
            }).then(() => {
                // console.log("saved")
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setInProgress(false)
            }
            );
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Segment>
            <Label as='a' color='blue' ribbon>
                Code Values
            </Label>
            <DataTable
                colDefinitions={colDefinitions}
                rowData={tableData.data}
                name=""
                onFormSubmit={handleFormSubmit}
                handleStateUpdate={setTableDataState}
                // handleChange={handleChange}
                allowEdit={true}
                tableActions={tableActionDefinitions}
                emptyRow={emptyRowDef}
                lookupCodeTableData={lookupCodeTableData}
                rowActions={rowActionDefinitions}
                reorderOn="displaySequence"
            />
            <Dimmer active={inProgress} page>
            <Header as="h2" icon inverted>
                <Icon name="spinner" color="orange" size="big" />
                Saving
            </Header>
            </Dimmer>

            <Dimmer active={dimmerActive} page>
                <Header as='h2' icon inverted>
                    <Icon name='check' color="green" />
                    Saved successfully!
                </Header>
            </Dimmer>
            <Dimmer active={dimmerDeleteFailed} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="green" />
              Deleted Successfully!
            </Header>
            </Dimmer>
            <Dimmer active={dimmerFailed} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="red" />
                All referenced data must be deleted to delete this record!
            </Header>
            </Dimmer>

        </Segment>

    )
}
export default CodeTableValues;