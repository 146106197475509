import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Form, Header, Icon, Label, Segment } from "semantic-ui-react";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { CODETABLE_TYPES, SelectOption } from "../../../models/codetable";
import { getCodeTableListByTypeCodes } from "../../codetable/codetable.service";

import {
  getFundingById,
  saveFundingProgram,
} from "../funding-program.service";

interface MappingType {
  typeOfProjectId: string,
  projectCategoryIds: string[],
  implementingEntityIds: string[],
  keywordIds: string[],
  eligibleLocationIds: string[],
  eligibleActivityIds: string[],
}

const FundingProgramEditComponent = () => {
  const params = useParams();
  const idParam = params?.id;
  const FundingProgramId = +params?.id;
  const defaultData = {
    name: "",
    eligibleRecipients: "",
    purpose: "",
    fundingAmountText: "",
    status: "",
    programAgency: "",
    link: "",
    typeOfProjectId: undefined,
    projectCategoryIds: [],
    implementingEntityIds: [], 
    keywordIds: [], 
    eligibleLocationIds: [] ,
    eligibleActivityIds: [],
    isActive: true,
  };

  const defaultCodeTables: { [key: string]: any[] } = useMemo(
    () => (
      {
      }
    ), [])

  const [codeTables, setCodeTables] = useState(defaultCodeTables)
  const [dimmerActive, setDimmerActive] = useState(false);
  const [editState, setEditState] = useState(defaultData);
  const navigate = useNavigate();
  const [isEditable, setEditable] = useState(typeof idParam === "undefined");
  const [inProgress,setInProgress]=useState(false)

  useEffect(() => {
    if (FundingProgramId) {
      getFundingById(FundingProgramId,(result)=>{
        if (result.data) {
          let stateData = { ...defaultData };
        Object.assign(stateData, result.data);
        if (!stateData["description"]) {
          stateData["description"] = "";
        }
        if(stateData['mapping']) {
          const mappingData:MappingType = stateData['mapping'];
          stateData.typeOfProjectId = +mappingData.typeOfProjectId
          stateData.eligibleActivityIds = mappingData.eligibleActivityIds.map(id => +id)
          stateData.eligibleLocationIds = mappingData.eligibleLocationIds.map(id => +id)
          stateData.projectCategoryIds = mappingData.projectCategoryIds.map(id => +id)
          stateData.implementingEntityIds = mappingData.implementingEntityIds.map(id => +id)
          stateData.keywordIds = mappingData.keywordIds.map(id => +id)
        }
        setEditState(stateData);
        }
    })   
    }
  }, []);

  useEffect(() => {
    getCodeTableListByTypeCodes(
      [CODETABLE_TYPES.PROJECT_TYPES, CODETABLE_TYPES.PROJECT_CATEGORY, CODETABLE_TYPES.IMPLEMENTING_ENTITY, CODETABLE_TYPES.ELIGIBLE_ACTIVITY, CODETABLE_TYPES.PROJECT_LOCATION, CODETABLE_TYPES.KEYWORD],
      (results) => {
      
        if (!results) {
          return;
        }
        setCodeTables(results);
      });
  }, [])

  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === "checkbox") {
      value = data.checked;
    }
    setEditState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnClickEdit = () => {
    setEditable(true);
  };

  const handleSubmit = () => {
    const eligibilityCopyAttrs = [
      "id",
      "name",
      "eligibleRecipients",
      "purpose",
      "fundingAmountText",
      "status",
      "programAgency",
      "link",
      "isActive",
    ];
    const mappingAttrs = [
      "projectCategoryIds",
      "implementingEntityIds",
      "keywordIds",
      "eligibleLocationIds",
      "eligibleActivityIds",
    ]
    
    const eligibilityData: any = {};
    eligibilityData['mapping']= {typeOfProjectId : ''+editState['typeOfProjectId']}
    for (let editAttr in editState) {
      if (eligibilityCopyAttrs.includes(editAttr)) {
        eligibilityData[editAttr] = editState[editAttr];
      } else if(mappingAttrs.includes(editAttr)) {
        eligibilityData['mapping'][editAttr] = editState[editAttr].map(id => ''+id);
      }
    }

    setInProgress(true)
    try {
      saveFundingProgram(eligibilityData, (result: any) => {
        const routePrefix = `/admin/funding-program`;
        if ([201, 200].includes(result.status)) {
          dimDelayNavigate(setDimmerActive, () => {
            navigate(routePrefix);
          });
        } else {
          console.log(result.message);
        }
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        }).finally(() => {
          setInProgress(false)
        }
        );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Segment raised>
        <Label as="a" color="red" ribbon>
          Funding Program
        </Label>

        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="name"
              label="Program Name"
              placeholder="Program Name"
              required
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.name}
            />
            <Form.Input
              fluid
              name="status"
              label="Status"
              placeholder="Status"
              required
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.status}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="fundingAmountText"
              label="Funding Amount Text"
              placeholder="Funding Amount Text"
              required
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.fundingAmountText}
            />
            <Form.Input
              fluid
              name="programAgency"
              label="Program Agency"
              placeholder="Program Agency"
              required
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.programAgency}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              // fluid
              name="eligibleRecipients"
              label="Eligible Recipients"
              placeholder="Eligible Recipients"
              required
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.eligibleRecipients}
            />
            <Form.TextArea
              // fluid
              name="purpose"
              label="Purpose"
              placeholder="Purpose"
              required
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.purpose}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="link"
              label="Link"
              placeholder="Link"
              onChange={handleChange}
              disabled={!isEditable}
              value={editState.link}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select  name="typeOfProjectId" label=' Type Of Project' placeholder=' Type Of Project'
              onChange={handleChange} disabled={!isEditable}
              value={editState.typeOfProjectId}
              options={codeTables[CODETABLE_TYPES.PROJECT_TYPES] || []}
            />
            <Form.Select  name="projectCategoryIds" label='Project Category' placeholder='Project Category'
              onChange={handleChange} disabled={!isEditable}
              value={editState.projectCategoryIds}
              options={codeTables[CODETABLE_TYPES.PROJECT_CATEGORY] || []} multiple
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select  name="eligibleActivityIds" label='Eligible Acitvity' placeholder='Eligible Acitvity'
              onChange={handleChange} disabled={!isEditable}
              value={editState.eligibleActivityIds}
              options={codeTables[CODETABLE_TYPES.ELIGIBLE_ACTIVITY] || []} multiple
            />
            <Form.Select  name="implementingEntityIds" label='Implementing Entity' placeholder='Implementing Entity'
              onChange={handleChange} disabled={!isEditable}
              value={editState.implementingEntityIds}
              options={codeTables[CODETABLE_TYPES.IMPLEMENTING_ENTITY] || []} multiple
            />
            
          </Form.Group>
          <Form.Group widths='equal'> 
            <Form.Select  name="eligibleLocationIds" label='Location' placeholder='Location'
              onChange={handleChange} disabled={!isEditable}
              value={editState.eligibleLocationIds}
              options={codeTables[CODETABLE_TYPES.PROJECT_LOCATION] || []} multiple
            />
            <Form.Select  name="keywordIds" label='Keyword' placeholder='Keyword'
              onChange={handleChange} disabled={!isEditable}
              value={editState.keywordIds}
              options={codeTables[CODETABLE_TYPES.KEYWORD] || []} multiple
            />
          </Form.Group>

          <Form.Group inline>
            <label>
              {isEditable
                ? "Active"
                : editState.isActive
                ? "Active: Yes"
                : "Inactive"}
            </label>
            {isEditable && (
              <Form.Checkbox
                name="isActive"
                defaultChecked={editState.isActive === true}
                toggle
                readOnly={!isEditable}
                onChange={handleChange}
              />
            )}
          </Form.Group>

          {isEditable && (
            <Form.Button color="blue" disabled={inProgress} icon>
              <Icon name="save" /> Save
            </Form.Button>
          )}
          {!isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="edit" /> Edit
            </Form.Button>
          )}
        </Form>
      </Segment>

      <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>

      <Dimmer active={dimmerActive} page>
        <Header as="h2" icon inverted>
          <Icon name="check" color="green" />
          Saved successfully!
          <Header.Subheader>Redirecting you to list page</Header.Subheader>
        </Header>
      </Dimmer>
    </div>
  );
};

export default FundingProgramEditComponent;
