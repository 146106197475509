import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Icon, Segment, Label, Dimmer, Header, Divider } from "semantic-ui-react";
import DataTable, { sortData } from "../../../components/data-table/data-table";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { deleteQuestion, getQuestionList, saveTaxonomyItems } from "../pca.service";

const PCAQuestionItems = (props: any) => {
  const emptyRow = {
    id: undefined,
    questionShort: "",
    isActive: true,
    editable: true,
  };
  const defaultRow = {
    data: [emptyRow],
  };
  const [dimmerActive, setDimmerActive] = useState(false)
  const [dimmerFailed, setDimmerFailed] = useState(false)
  const [dimmerNoData, setDimmerNoData] = useState(false)
  const [tableData, setTableDataState] = useState(defaultRow);
  const pcaId = props.pcaId;
  const projectComponentId = props.projectComponentId;
  const subCriteriaId = props.subCriteriaId;
  const componentType = props.name;
  const parentId = props.parentId;
  const taxonomyType = props.taxonomyType;
  const parentPrefix = props.parentPrefix;
  const routePrefix = `${parentPrefix}/${parentId}`;
  const colDefinitions = [
    { name: "ID", field: "id", type: "number" },
    { name: "Question", field: "questionShort", type: "text" },
    { name: "Description", field: "description", type: "text" },
    { name: "Enabled", field: "isActive", type: "toggle" },
  ];

  const routeName = `${routePrefix}/question/new?subcriteriaId=${subCriteriaId}`;

  const tableActionDefinitions = [
    {
      name: "Add Question",
      icon: "add",
      type: "add inline",
      color: "green",
      placeIn: "header",
      route: routeName,
    },
  ];
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false)
  useEffect(() => {

    getQuestionList(subCriteriaId,(result)=>{
              
      if (result.data) {
        const newStateData = result.data.map((rowItem) => {
          rowItem.editable = false;
          return rowItem;
        });
        const sortedData = sortData(newStateData, "displaySequence", true);
        setTableDataState({ data: sortedData });
      }
     
  })
   
  }, []);

  if (!props.pcaId) {
    return null;
  }

  const handleOnClickViewDetail = (evt, rowIndex, rowId) => {
    if (rowId) {
      navigate(`${routePrefix}/question/${rowId}`);
    }
  };
  const rowActionDefinitions = [
    {
      name: `Edit Question`,
      icon: "edit",
      type: "viewchild",
      color: "yellow",
      onClick: handleOnClickViewDetail,
    },
    {
      name: "Delete",
      icon: "trash",
      color: "red",
      onClick: (evt, rowIndex, rowId) => {
        handleOnClickDelete(rowId)
      },

    },
  ];
  const handleOnClickDelete = (rowId: any) => {
    deleteQuestion(rowId, (result: any) => {
      if (result.status == 200) {
        dimDelayNavigate(setDimmerFailed, () => result?.data?.message)
        window.location.reload()
      }
    })
  };
  const handleFormSubmit = () => {
    // return;

    const allowedProps = ["id", "name", "isActive", "parentId"];
    const itemValues = tableData.data
      .filter((rowItem) => rowItem.questionShort)
      .map((rowItem) => {
        let rowData: { [key: string]: any } = {};
        allowedProps.forEach(
          (propName) => (rowData[propName] = rowItem[propName])
        );
        rowData["pcaId"] = pcaId;
        rowData["parentId"] = parentId;
        return rowData;
      });
    setInProgress(true)
    try {
      saveTaxonomyItems(
        itemValues,
        pcaId,
        taxonomyType,
        projectComponentId,
        (result: any) => {
          if ([201, 200].includes(result.status)) {
            dimDelayNavigate(setDimmerActive, () => {
              navigate(routePrefix);
            });
          } else {
          }
        }
      )
        .then(() => { })
        .catch((error) => { }).finally(() => {
          setInProgress(false)
        }
        );
    } catch (error) { }
  };

  return (
    <Segment>
      <Label as="a" color="blue" ribbon>
        {componentType}
      </Label>
      <DataTable
        colDefinitions={colDefinitions}
        rowData={tableData.data}
        name=""
        onFormSubmit={handleFormSubmit}
        handleStateUpdate={setTableDataState}
        allowEdit={true}
        tableActions={tableActionDefinitions}
        rowActions={rowActionDefinitions}
      
      />

      <Dimmer active={inProgress} page>
        <Header as="h2" icon inverted>
          <Icon name="spinner" color="orange" size="big" />
          Saving
        </Header>
      </Dimmer>

      <Dimmer active={dimmerActive} page>
        <Header as="h2" icon inverted>
          <Icon name="check" color="green" />
          Saved successfully!
          <Header.Subheader>Redirecting you to list page</Header.Subheader>
        </Header>
      </Dimmer>

      <Dimmer active={dimmerFailed} page>
        <Header as='h2' icon inverted>
          <Icon name='check' color="green" />
          Deleted Successfully!
        </Header>
      </Dimmer>

      <Dimmer active={dimmerNoData} page>
        <Header as='h2' icon inverted>
          <Icon name='check' color="red" />

          No Data Available...
        </Header>
      </Dimmer>
    </Segment>
  );
};
export default PCAQuestionItems;
