import Axios from 'axios';
import { Content } from '../../models/content';
import { buildAPIURL, getAxiosDefaultOptions } from '../../utils/common-utils';

export async function getContentList(typeCode, callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/content/${typeCode}`),
        options).then(async (response) => {
            callback(response);
        })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
}

export async function getContentDetail(id: number | string,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/content/${id}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function getUser(id: number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/user/${id}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};


export async function saveContent(data: Content, callback: any) {
    const options = getAxiosDefaultOptions();
    const requestMethod = data.id ? Axios.patch : Axios.post
    const pathSuffix = data.id ? `/${data.id}` : '';

    await requestMethod(buildAPIURL(`/content${pathSuffix}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};













