import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {Icon, Segment, Label, Dimmer, Header, Divider } from "semantic-ui-react";
import DataTable from "../../../components/data-table/data-table";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { deleteCodeTable, getCodeTableList } from "../codetable.service";

const CodeTableList = () => {
  const defaultRow = {
    data: [{}],
  };
  const emptyRow = useMemo(
    () => ({ id: undefined, name: "", isActive: true, description: "" }),
    []
  );

  const navigate = useNavigate();

  const [tableData, setTableDataState] = useState(defaultRow);
  const colDefinitions = [
    { name: "ID", field: "id", type: "number" },
    { name: "Code Table Name", field: "name", type: "text" },
    { name: "Type Key", field: "typeCode", type: "text" },
    { name: "Enabled", field: "isActive", type: "boolean" },
  ];
  const [dimmerActive, setDimmerActive] = useState(false)
  const [dimmerFailed, setDimmerFailed] = useState(false)
  const actionDefinitions = [
    {
      name: "Edit",
      icon: "edit",
      onClick: (evt, rowIndex, rowId) => {
        navigate(`/admin/codetable/${rowId}`);
      },
      
    },
    {
      name: "Delete",
      icon: "trash",
      onClick: (evt, rowIndex, rowId) => {
        handleOnClickDelete(rowId)
      },
      
    },
  ];

  const handleOnClickDelete= (rowId:any) => {
  
    deleteCodeTable(rowId, (result: any) => {
    
    if (result.data.statusCode == 202) {
       
        dimDelayNavigate(setDimmerActive, () =>result?.data?.message )
        window.location.reload()
     } 
     else
     {
      dimDelayNavigate(setDimmerFailed, () =>result?.data?.message )
     }
   })
   };
  const tableActionDefinitions = [
    {
      name: "Add Row",
      icon: "add",
      route: "/admin/codetable/new",
      color: "green",
      placeIn: "header",
    },
  ];

  useEffect(() => {
    getCodeTableList((result) => {
      if (result.data.data) {
       
        setTableDataState({ data: result.data.data });
      }
    })
  }, []);

  return (
    <div>
      <div className="ag-theme-alpine" style={{ height: 500 }}>
        <Divider hidden />

        <DataTable
          colDefinitions={colDefinitions}
          rowData={tableData.data}
          name=""
          rowActions={actionDefinitions}
          tableActions={tableActionDefinitions}
          emptyRow={emptyRow}
        />
           <Dimmer active={dimmerActive} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="green" />
              Deleted Successfully!
            </Header>
            </Dimmer>
            <Dimmer active={dimmerFailed} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="red" />
                All referenced data must be deleted to delete this record!
            </Header>
            </Dimmer>
            
      </div>
    </div>
  );
};

export default CodeTableList;
