import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dimmer, Form, Header, Icon, Label, Segment } from "semantic-ui-react";
import { saveFundingEligibilityEntry, getFundingProgramList, getFundingEligibilityDetail } from "../funding-eligibility.service";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { CODETABLE_TYPES, SelectOption } from "../../../models/codetable";
import { getCodeTableListByTypeCodes } from "../../codetable/codetable.service";
const FundingEligibilityEditComponent = () => {

  const params = useParams()
  const idParam = params?.id
  const id = +params?.id
  const defaultData = useMemo(
    () => (
      { mapping:{}as any, isActive: true, fundingProgramId: undefined, projectCategoryIds:undefined,typeOfProjectId: undefined, implementingEntityIds: undefined, keywordIds: undefined, eligibleLocationIds: undefined ,eligibleActivityIds: undefined, description: '' }
    ), [])
  const [dimmerActive, setDimmerActive] = useState(false)
  const [editState, setEditState] = useState(defaultData)
  const navigate = useNavigate()
  const [isEditable, setEditable] = useState(typeof idParam === 'undefined')
  const defaultCodeTables: { [key: string]: any[] } = useMemo(
    () => (
      {
      }
    ), [])

  const [codeTables, setCodeTables] = useState(defaultCodeTables)
  const emptyArr: SelectOption[] = []
  const [fundingPrograms, setFundingPrograms] = useState(emptyArr)
  const [inProgress, setInProgress] = useState(false)
  useEffect(() => {
    
    if (id) {
      getFundingEligibilityDetail(id,(result)=>{
       if (result.data) {
          let stateData = {...defaultData};
          Object.assign(stateData, result.data)
          if(!stateData['description']) {
            stateData['description'] = ''
          }
          stateData={...stateData,...stateData.mapping}
          setEditState(stateData)
        }
    })
       }
   
  }, [id, defaultData])


  useEffect(() => {
    getCodeTableListByTypeCodes(
      [CODETABLE_TYPES.PROJECT_TYPES, CODETABLE_TYPES.PROJECT_CATEGORY, CODETABLE_TYPES.IMPLEMENTING_ENTITY, CODETABLE_TYPES.ELIGIBLE_ACTIVITY, CODETABLE_TYPES.PROJECT_LOCATION, CODETABLE_TYPES.KEYWORD],
      (results) => {
      
        if (!results) {
          return;
        }
        setCodeTables(results);
      });
      
      getFundingProgramList((result) => {
            if (result) {
          setFundingPrograms( result );
        }
      })   
  }, [])


  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === 'checkbox') {
      value = data.checked
    }
    setEditState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleOnClickEdit = () => {
    setEditable(true)
  }


  const handleSubmit = () => {
    const eligibilityCopyAttrs = ['id', 'typeOfProjectId',  'fundingProgramId',  'isActive']

   // const eligibilityCopyAttrs = ['id', 'typeOfProjectId', 'implementingEntityIds', 'projectCategoryIds', 'eligibleActivityIds', 'fundingProgramId','keywordIds', 'eligibleLocationIds', 'isActive']

    const eligibilityData: any = {}
    for (let editAttr in editState) {
      if (eligibilityCopyAttrs.includes(editAttr)) {
        eligibilityData[editAttr] = editState[editAttr]
      }
    }
    setInProgress(true)
    try {
      const mapping={
        implementingEntityIds:editState.implementingEntityIds,
        projectCategoryIds:editState.projectCategoryIds,
        eligibleActivityIds:editState.eligibleActivityIds,
        keywordIds:editState.keywordIds,
        eligibleLocationIds:editState.eligibleLocationIds
      }
      const payload={
        ...eligibilityData,mapping
      }
      const dataKeys = Object.keys(mapping);
dataKeys.forEach(item => {
  if(mapping[item] && Array.isArray(mapping[item])) {
    mapping[item] = mapping[item].map(itemVal => itemVal.toString());
  }  
})
      saveFundingEligibilityEntry(payload, (result: any) => {
        const routePrefix = `/admin/funding-eligibility`
        if ([201, 200].includes(result.status)) {
         
          dimDelayNavigate(setDimmerActive, () => { navigate(routePrefix) })
        }
        else {
          console.log(result.message);
        }
      }).then(() => {
        // console.log("saved")
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setInProgress(false)
      }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Segment raised>
        <Label as='a' color='red' ribbon>
          Funding Eligibility Criteria Mapping
        </Label>

        <Form onSubmit={handleSubmit} >
          <Form.Group widths='equal'>
            <Form.Select fluid name="fundingProgramId" label='Program Name' placeholder='Program Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.fundingProgramId}
              options={fundingPrograms}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select fluid name="typeOfProjectId" label=' Type Of Project' placeholder=' Type Of Project'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.typeOfProjectId}
              options={codeTables[CODETABLE_TYPES.PROJECT_TYPES] || []}
            />
            <Form.Select fluid name="projectCategoryIds" label='Project Category' placeholder='Project Category'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.mapping.projectCategoryIds}
              options={codeTables[CODETABLE_TYPES.PROJECT_CATEGORY] || []} multiple
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Select fluid name="eligibleActivityIds" label='Eligible Acitvity' placeholder='Eligible Acitvity'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.mapping.eligibleActivityIds}
              options={codeTables[CODETABLE_TYPES.ELIGIBLE_ACTIVITY] || []} multiple
            />
            <Form.Select fluid name="implementingEntityIds" label='Implementing Entity' placeholder='Implementing Entity'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.mapping.implementingEntityIds}
              options={codeTables[CODETABLE_TYPES.IMPLEMENTING_ENTITY] || []} multiple
            />
            
          </Form.Group>
          <Form.Group widths='equal'> 
            <Form.Select fluid name="eligibleLocationIds" label='Location' placeholder='Location'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.mapping.eligibleLocationIds}
              options={codeTables[CODETABLE_TYPES.PROJECT_LOCATION] || []} multiple
            />
            <Form.Select fluid name="keywordIds" label='Keyword' placeholder='Keyword'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.mapping.keywordIds}
              options={codeTables[CODETABLE_TYPES.KEYWORD] || []} multiple
            />
          </Form.Group>

          <Form.Group inline>
            <label>{isEditable ? "Active" : editState.isActive ? "Active: Yes" : "Inactive"}</label>
            {isEditable &&
              <Form.Checkbox
                // label='Active'
                // value='true'
                name="isActive"
                defaultChecked={editState.isActive === true}
                toggle
                readOnly={!isEditable}
                // checked={editState.isActive === true}
                onChange={handleChange}
              />
            }
          </Form.Group>

          {isEditable &&
            <Form.Button color="blue" disabled={inProgress} icon><Icon name='save' /> Save</Form.Button>
          }
          {!isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="edit" /> Edit
            </Form.Button>
          )}
        </Form>
      </Segment>
      <Dimmer active={inProgress} page>
        <Header as="h2" icon inverted>
          <Icon name="spinner" color="orange" size="big" />
          Saving
        </Header>
      </Dimmer>

      <Dimmer active={dimmerActive} page>
        <Header as="h2" icon inverted>
          <Icon name="check" color="green" />
          Saved successfully!
          <Header.Subheader>Redirecting you to list page</Header.Subheader>
        </Header>
      </Dimmer>
    </div>
  )
}

export default FundingEligibilityEditComponent;