import { useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Divider,
  Form,
  Icon,
  Label,
  Segment,
  Dimmer,
  Header,
} from "semantic-ui-react";
import { PCA, savePCA, getTaxonomyItem } from "../pca.service";
import PCACriteriaItems from "./pca-edit-criteria-items.component";
import { dimDelayNavigate } from "../../../utils/common-utils";
const PCACriteriaEditComponent = () => {

  const params = useParams()
  const idParam = params?.criteriaId
  const criteriaId = +idParam
  const pcaId = +params?.pcaId
  const projectComponentId = +params?.projectComponentId
  const defaultData = { name: '', isActive: true, description: '', pca: {name: ''}, parent: {name: ''} }
  const detailName = 'Criteria'
  const detailItemsName = 'Sub-Criteria'
  const [dimmerActive, setDimmerActive] = useState(false);
  const [editState,setEditState] = useState(defaultData)
  const navigate = useNavigate()
  const [isEditable, setEditable] = useState(typeof idParam === 'undefined')
  const [inProgress,setInProgress]=useState(false)
  useEffect(() => {
    if(pcaId) {
      getTaxonomyItem(criteriaId, 'criteria',(result)=>{
                 if (result.data) {
          let stateData = {...defaultData};
            Object.assign(stateData, result.data)
            if(!stateData['description']) {
              stateData['description'] = ''
            }
            setEditState(stateData)
                }
       
    })
    
    }
  }, [pcaId])

  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === 'checkbox') {
      value = data.checked
    }

    setEditState(prevState => ({...prevState,  [name]: value }))
  }

  const handleOnClickEdit = () => {
    setEditable(true)
  }


  const handleSubmit = () => {
    const codeTableData: PCA = editState
    setInProgress(true)
    try {
      savePCA(codeTableData, (result: any) => {
        const routePrefix = `/admin/pca/${pcaId}/project-component/${projectComponentId}`
        
     
        if ([201, 200].includes(result.status)) {
         
          dimDelayNavigate(setDimmerActive, () => {navigate(routePrefix)})
       
      }
        else {
          console.log(result.message);
        }
      }).then(() => {
        console.log("saved")
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setInProgress(false)
      }
      );
    } catch (error) {
      console.log(error);
    }
  }

    return (
      <div>
        <Segment raised>
        <Label as='a' color='red' ribbon>
          {detailName} Details
        </Label>
       
        <Form onSubmit={handleSubmit} >
          <Form.Group widths='equal'>
            <Form.Input fluid name="pcaName" label='PCA Name' placeholder='PCA Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.pca.name}
              />
            <Form.Input fluid name="name" label='Project Component Name' placeholder='Project Component Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.parent.name}
              />
          </Form.Group>
          <Form.Input fluid name="name" label='Criteria Name' placeholder='Criteria Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.name}
              />
          {/* <Form.TextArea name="description" label='Description' placeholder='Description about where the PCA is used'
           onChange={handleChange}
           value={editState.description}  readOnly={!isEditable}
           /> */}
           
          <Form.Group inline>
            <label>{ isEditable? "Active" :  editState.isActive ? "Active: Yes" : "Inactive"}</label>
            {isEditable &&
            <Form.Checkbox
              // label='Active'
              // value='true'
              name="isActive"
              defaultChecked={editState.isActive === true}
              toggle
              readOnly={!isEditable}
              // checked={editState.isActive === true}
              onChange={handleChange}
              />
            }
          </Form.Group>
          {isEditable &&
          <Form.Button  color="blue" disabled={inProgress} icon><Icon name='save' /> Save</Form.Button>
          }
           {!isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="edit" /> Edit
            </Form.Button>
          )}
        </Form>

        <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>

        <Dimmer active={dimmerActive} page>
          <Header as="h2" icon inverted>
            <Icon name="check" color="green" />
            Saved successfully!
            <Header.Subheader>Redirecting you to list page</Header.Subheader>
          </Header>
        </Dimmer>
        </Segment>
        <Divider />
        
        <PCACriteriaItems 
        name={detailItemsName} 
        pcaId={pcaId} 
        taxonomyType="sub-criteria"
        childTaxonomyType="sub-criteria"
        parentId={criteriaId} 
        projectComponentId={projectComponentId} 
        criteriaId={criteriaId}
        parentPrefix={ `/admin/pca/${pcaId}/project-component/${projectComponentId}/criteria`}
        codeTable={editState}
        parentData={editState}
        />

      </div>
    )
}

export default PCACriteriaEditComponent;