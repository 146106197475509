import { useNavigate, useLocation } from 'react-router';
import { Button, Divider, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import axios from 'axios';
import { buildAPIURL } from '../../utils/common-utils';
import { useAuth } from '../../hooks/useAuth';
import { useState, useEffect } from 'react';

const LoginPage = () => {
  const [hasError, setHasError] = useState(false);
  const [userFormState, setUserFormState] = useState({ username: '', password: '' });

  const navigate = useNavigate();
  let location = useLocation();
  let from = "/admin";

  if (location.state) {
    from = location.state['from']?.pathname || "/admin";
  }

  const { user, login } = useAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const user = formData.get("username") as string;
    const pass = formData.get("password") as string;

    axios.post(buildAPIURL('/auth/login'), { username: user, password: pass })
      .then((res) => {
        if (res.status === 200 && res.data.userRoles !== 'user') {
          login({
            email: user,
            token: res.data.access_token,
            userRoles: res.data.userRoles
          });
          navigate("/admin", { replace: true });
        } else if (res.status === 200 && res.data.userRoles === 'user') {
          setHasError(true);     
        } else {
          setHasError(true);
        }
      })
      .catch(error => {
        setHasError(true);
      });
  }

  useEffect(() => {
    if (user && user.userRoles === 'user') {
      setHasError(true);
    }
  }, [user]);

  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='blue' textAlign='center'>
          <Image src='/logo-retina.png' /> IIT CMS Tool
        </Header>
        <Header as='h4' color='grey' textAlign='left'>
          Login with your admin credentials
        </Header>
        <Form size='large' error={hasError} onSubmit={handleSubmit}>
          <Segment raised>
            <Form.Input fluid icon='user'
              iconPosition='left'
              name="username"
              placeholder='E-mail address'
              error={hasError}
            />
            <Form.Input
              fluid
              icon='lock'
              name="password"
              iconPosition='left'
              placeholder='Password'
              type='password'
              error={hasError}
            />
            <Divider hidden />
            {hasError && (
              <Message
                error
                header='Login Failed'
                content={
                  hasError && user && user.userRoles === 'user'
                 
                    ?  'Invalid username and password'
                    : 'User access denied'
                }
              />
            )}
            <Button color='blue' fluid size='large'>
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginPage;
