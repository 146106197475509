import { Header, } from 'semantic-ui-react'
import { Route, Routes } from 'react-router';
import UsersList from './components/users-list.component';
import UsersEditComponent from './components/users-edit.component';
import AdminEditComponent from './components/admin-edit-component';
const UsersPage = () => {


 const GetPageRoutes = function() {
        return (
          <Routes>
            <Route path="/" element={<UsersList />}/> 
            <Route path=":id" element={<UsersEditComponent />} />
            <Route path="new" element={<UsersEditComponent />} />
            <Route path="admin" element={<AdminEditComponent />} />
            <Route path=":id/admin" element={<AdminEditComponent />} />
          </Routes>
        )
 } 

 return (
   <div>
    <Header as='h2' className='left' textAlign='left'>
        <Header.Content>
          Users
            <Header.Subheader>
              Manage the registered users here. You can also create new users here.
            </Header.Subheader>
        </Header.Content>
    </Header>
    <GetPageRoutes />
   </div>
 );
};

export default UsersPage;