import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Dimmer, Divider, Form, Header, Icon, Label, Segment } from "semantic-ui-react";
import DataTable, { sortData } from "../../../components/data-table/data-table";
import { CODETABLE_TYPES, SelectOption } from "../../../models/codetable";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { getCodeTableListByTypeCodes } from "../../codetable/codetable.service";
import { getQuestion, getTaxonomyItem, saveQuestion } from "../pca.service";

const PCAQuestionEditComponent = () => {

  const params = useParams()
  const idParam = parseInt(params?.subCriteriaId)
  const pcaId = +params?.pcaId
  const subCriteriaId = +idParam
  const questionId = +params.questionId

  const defaultData = useMemo(
    () => (
      { questionShort: '', name: '', pcaId: undefined, isActive: true, questionWeight: 0, priorityAreaId: undefined, description: '', pca: { name: '', id: undefined }, subCriteria: { name: '' }, criteria: { name: '' }, projectComponent: { name: '' } }
    ), [])

  // const defaultData = { questionShort: '', name: '', pcaId: undefined, isActive: true, questionWeight: 0, priorityArea: {id: undefined}, description: '', pca: { name: '', id: undefined }, subCriteria: { name: '' }, criteria: { name: '' }, projectComponent: { name: '' } }

  const emptyRow = { id: undefined, 'choiceText': '', 'weight': 0, 'isActive': true, "editable": true, 'questionId': undefined, "showInput": false, priorityAreaId: undefined };
  const defaultRow = {
    data: [
      emptyRow,
    ]
  }
  const [dimmerActive, setDimmerActive] = useState(false)
  const [choicesData, setChoicesDataState] = useState(defaultRow)
  const [editState, setEditState] = useState(defaultData)
  const [isEditable, setEditable] = useState(true || typeof idParam === 'undefined')
  const [dataVersion, setDataVersion] = useState(1)
  const defaultSelectOptions: SelectOption[] = []
  const [priorityAreaOptions, setPriorityAreaOptions] = useState(defaultSelectOptions)

  const colDefinitions = [
    { name: 'Answer Choice Text', field: 'choiceText', type: 'text' },
    { name: 'Weight', field: 'weight', type: 'text', collapsing: false },
    { name: 'Feedback', field: 'feedback', type: 'text', collapsing: false },
    { name: 'Include Input', field: 'showInput', type: 'toggle', collapsing: true },
  ];
  const rowActionDefinitions = [
    { name: 'Add', icon: 'add', type: 'add inline', color: 'green' }
  ]

  // const 
  const tableActionDefinitions = [
    {
      name: 'Add Choice', icon: 'add', type: 'add inline',
      color: 'green', placeIn: 'header',
      // route: routeName
    }
  ]
  const [inProgress, setInProgress] = useState(false)
  const navigate = useNavigate()


  useEffect(() => {
    getCodeTableListByTypeCodes([CODETABLE_TYPES.PRIORITY_AREAS],results=>{
      if (!results) {
        return;
      }
      if (results[CODETABLE_TYPES.PRIORITY_AREAS]) {
        setPriorityAreaOptions(results[CODETABLE_TYPES.PRIORITY_AREAS]);
      }
    })
     
  }, [])


  useEffect(() => {

    if (questionId) {

      getQuestion(questionId, (result) => {
        if (result.data) {
          let stateData = { ...defaultData };
          Object.assign(stateData, result.data)
          if (!stateData['description']) {
            stateData['description'] = ''
          }

          stateData['pcaId'] = pcaId
          stateData['pca']['id'] = pcaId

          setEditState(stateData)


          let sortedData = []
          if (result.data?.choices?.length > 0) {
            const newStateData = result.data.choices.map(rowItem => {
              rowItem.editable = true

              return rowItem;
            })
            sortedData = sortData(newStateData, 'displaySequence', true)
          }
          setChoicesDataState({ data: sortedData })
        }
      })

    }
    else if (idParam) {
      getTaxonomyItem(idParam, 'sub-criteria', (result) => {
        if (result.data) {
          result.data.projectComponent = result.data.parent.parent
          result.data.criteria = result.data.parent
          result.data.subCriteria = result.data


          let stateData = { ...defaultData };
          Object.assign(stateData, result.data)
          if (!stateData['description']) {
            stateData['description'] = ''
          }

          setEditState(stateData)
        }
      })


    }

  }, [defaultData, idParam, pcaId, questionId, dataVersion])

  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === 'checkbox') {
      value = data.checked
    }

    setEditState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleOnClickEdit = () => {
    setEditable(true)
  }


  const handleSubmit = () => {

    const qCopyAttrs = ['id', 'questionShort', 'description', 'isActive', 'pcaTaxonomyId', 'pcaId', 'questionWeight', 'priorityAreaId']
    const choiceCopyAttrs = ['id', 'choiceText', 'description', 'isActive', 'weight', 'showInput', 'feedback']

    const questionData: any = {}
    for (let editAttr in editState) {
      if (qCopyAttrs.includes(editAttr)) {
        questionData[editAttr] = editState[editAttr]
      }
    }
    questionData.pcaTaxonomyId = subCriteriaId

    questionData.choices = choicesData?.data.filter(choiceItem => choiceItem.choiceText.length > 0).map(choiceItem => {
      const choice: any = {}
      for (let editAttr in choiceItem) {
        if (choiceCopyAttrs.includes(editAttr)) {
          choice[editAttr] = choiceItem[editAttr]
        }
      }
      return choice;
    })

    questionData.pcaId = pcaId

    setInProgress(true)
    try {
      saveQuestion(questionData, (result: any) => {
        if ([201, 200].includes(result.status)) {

          dimDelayNavigate(setDimmerActive, () => { setDataVersion(dataVersion + 1) })

        }
        else {

        }
      }).then(() => {

      }).catch(error => {

      }).finally(() => {
        setInProgress(false)
      }
      );
    } catch (error) {

    }
  }

  return (
    <div >
      <Segment raised style={{ height: "100vh" }}>
        <Label as='a' color='red' ribbon>
          Edit Question
        </Label>

        <Breadcrumb>
          {/* <Breadcrumb.Section link>{editState.pca.name}</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section link>{editState.projectComponent.name}</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section link>{editState.criteria.name}</Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section link>{editState.subCriteria.name}</Breadcrumb.Section>
          <Breadcrumb.Divider /> */}
          <Breadcrumb.Section active>Question</Breadcrumb.Section>
        </Breadcrumb>
        <Divider />
        <Form onSubmit={handleSubmit} >

          <Form.Group >
            <Form.Input width={12} fluid name="questionShort" label='Question Short' placeholder='Enter Question'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.questionShort}
            />
          </Form.Group>
          <Form.Group >

            <Form.TextArea width={12} name="description" label='Description' placeholder='Description about question'
              onChange={handleChange}
              value={editState.description} readOnly={!isEditable}
            />
            <Form.Select width={4} fluid name="priorityAreaId" label='Federal Priority Area' placeholder='Federal Priority Area'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.priorityAreaId}
              options={priorityAreaOptions}
            />
          </Form.Group>
        </Form>
        <Divider hidden />

        <DataTable
          colDefinitions={colDefinitions}
          rowData={choicesData.data}
          name="Choices"
          handleStateUpdate={setChoicesDataState}
          // rowActions={rowActionDefinitions}
          onFormSubmit={handleSubmit}
          tableActions={tableActionDefinitions}
          allowEdit={true}
          emptyRow={emptyRow}
        />
        <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>

        <Dimmer active={dimmerActive} page>
          <Header as='h2' icon inverted>
            <Icon name='check' color="green" />
            Saved successfully!
            {/* <Header.Subheader>Redirecting you to list page</Header.Subheader> */}
          </Header>
        </Dimmer>
      </Segment>
    </div>
  )
}

export default PCAQuestionEditComponent;

function stateData(stateData: any) {
  throw new Error("Function not implemented.");
}
