import Axios from 'axios';
import { buildAPIURL, getAxiosDefaultOptions } from '../../utils/common-utils';

export async function getPCAList(callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL('/pca?includeInactive'), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function getPCADetail(id:number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/pca/${id}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};


export interface PCA {
    name: string;
    id?: number;
    description?: string;
    isActive: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}
export interface PCATaxonomy {
    name: string;
    id?: number;
    pcaId: number;
}
export interface Question {
    id?: number;
    questionShort: string;
    description?: string;
    isActive: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    pcaTaxonomyId?: number;
    pcaId: number;
    choices? : AnswerChoices[];
}
export interface AnswerChoices {
    id?: number;
    choiceText: string;
    weight: number;
    isActive: boolean;
    showInput: boolean;
    questionId?: number;

}

export async function savePCA(data: PCA, callback: any) {
    const options = getAxiosDefaultOptions();
    const requestMethod = data.id ? Axios.patch : Axios.post
    const pathSuffix = data.id ? `/${data.id}` : '';
   
    await requestMethod(buildAPIURL(`/pca${pathSuffix}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};


export async function getProjectComponents( pcaId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = '';
    await Axios.get(buildAPIURL(`/pca-taxonomy/project-component?pcaId=${pcaId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getProjectComponent( projectComponentId:number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = '';
    await Axios.get(buildAPIURL(`/pca-taxonomy/project-component/${projectComponentId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};



export async function saveProjectComponents(data: any[], pcaId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = '';
    await Axios.post(buildAPIURL(`/pca-taxonomy/project-component${pathSuffix}?pcaId=${pcaId}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getTaxonomyList(pcaId: number, taxonomyType: 'project-component'|'criteria'|'sub-criteria', parentId:number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/pca-taxonomy/${taxonomyType}?pcaId=${pcaId}&parentId=${parentId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getTaxonomyItem(taxonomyId: number, taxonomyType: 'project-component'|'criteria'|'sub-criteria',callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/pca-taxonomy/${taxonomyType}/${taxonomyId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};





export async function saveTaxonomyItems(data: any[], pcaId: number, taxonomyType: 'project-component'|'criteria'|'sub-criteria', parentId:number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = '';
    await Axios.patch(buildAPIURL(`/pca-taxonomy/${taxonomyType}${pathSuffix}?pcaId=${pcaId}&parentId=${parentId}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getCriteriaList(projectComponentId:number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/pca-taxonomy/criteria?parentId=${projectComponentId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getCriteria(criteriaId:number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/pca-taxonomy/criteria/${criteriaId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};



export async function saveCriteriaItems(data: any[], pcaId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = '';
    await Axios.post(buildAPIURL(`/pca-taxonomy/criteria${pathSuffix}?pcaId=${pcaId}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function getQuestionList(subCriteriaId:number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/question?subCriteriaId=${subCriteriaId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function getQuestion(questionId:number,callback: any) {
    const options = getAxiosDefaultOptions();
    await Axios.get(buildAPIURL(`/question/${questionId}`), options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};


export async function saveQuestion(data: Question, callback: any) {
    const options = getAxiosDefaultOptions();
    console.log('options', options)
    const requestMethod = data.id ? Axios.put : Axios.post
    const pathSuffix = data.id ? `/${data.id}` : '';
    // const payload = { codeTable: data , codeTableValue: []}
    await requestMethod(buildAPIURL(`/question${pathSuffix}`),
        data, options).then(async (response) => {
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};
export async function deletePca(rowId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = rowId ? `/${rowId}` : '';
    // const payload = { codeTable: data , codeTableValue: []}
    await Axios.delete (buildAPIURL(`/pca${pathSuffix}`), options
       ).then(async (response) => {
        console.log(response,"res")
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
};

export async function deletePcaTaxonomy(rowId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = rowId ? `/${rowId}` : '';
    // const payload = { codeTable: data , codeTableValue: []}
    await Axios.delete (buildAPIURL(`/pca-taxonomy${pathSuffix}`), options
       ).then(async (response) => {
        console.log(response,"res")
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
        
};

export async function deleteQuestion(rowId: number, callback: any) {
    const options = getAxiosDefaultOptions();
    const pathSuffix = rowId ? `/${rowId}` : '';
    // const payload = { codeTable: data , codeTableValue: []}
    await Axios.delete(buildAPIURL(`/question${pathSuffix}`), options
       ).then(async (response) => {
        console.log(response,"res")
        callback(response);
    })
        .catch(function (error) {
            if (error.response) {
                callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
            }
        })
        
};