import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Icon, Label, Segment, Dimmer, Header, } from "semantic-ui-react";
import { dimDelayNavigate } from "../../../utils/common-utils";
import { User, saveUsers, getUser } from "../users.service";

const AdminEditComponent = () => {
  const params = useParams()
  const idParam = params?.id
  const userId = +params?.id
  const defaultData = { firstName: '', lastName: '', email: '', isActive: true, isDefault: false }
  const [dimmerActive, setDimmerActive] = useState(false);
  const [editState, setEditState] = useState(defaultData)
  const navigate = useNavigate()
  const [isEditable, setEditable] = useState(typeof idParam === 'undefined')
  const [inProgress, setInProgress] = useState(false)

  useEffect(() => {
    if (userId) {
      getUser(userId, (result) => {
        if (result.data) {
          let stateData = { ...defaultData };
          Object.assign(stateData, result.data)
          if (!stateData['description']) {
            stateData['description'] = ''
          }
          setEditState(stateData)
        }
      })
    }
  }, [userId])

  const handleChange = (e, data: any) => {
    const { name } = data;
    let { value } = data;
    if (data.type === 'checkbox') {
      value = data.checked
    }

    setEditState(prevState => ({ ...prevState, [name]: value }))
  }

  const handleOnClickEdit = () => {
    setEditable(true)
  }


  const handleSubmit = () => {
    const userTableData: any = editState
    setInProgress(true)
    try {
      
      const formData = new FormData();
      // formData.append("firstName", userTableData.firstName);
      saveUsers(userTableData, (result: any) => {
        const routePrefix = `/user/${result.data}`
        if ([201, 200].includes(result.status)) {

          dimDelayNavigate(setDimmerActive, () => { navigate(routePrefix) })

        }
        else {
          console.log(result.message);
        }
      }).then((data) => {
        console.log("saved")
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        setInProgress(false)
      }
      );
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Segment raised>
        <Label as='a' color='red' ribbon>
          Users Details
        </Label>

        <Form
          onSubmit={handleSubmit}
        >
          <Form.Group widths='equal'>
            <Form.Input fluid name="firstName" label='First Name' placeholder='First Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.firstName}
            />

            <Form.Input fluid name="lastName" label='Last Name' placeholder='Last Name'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.lastName}
            />
          </Form.Group>

          <Form.Group widths='equal'>
            <Form.Input fluid name="email" label='Email' placeholder='Email'
              required onChange={handleChange} readOnly={!isEditable}
              value={editState.email}
            />

          </Form.Group>
          <Form.Group widths='equal'>
          </Form.Group>

          <Form.Group inline>
            <label>{isEditable ? "Enabled" : editState.isActive ? "Enabled: Yes" : "Enabled: No"}</label>
            {isEditable &&
              <Form.Checkbox
                name="isActive"
                defaultChecked={editState.isActive === true}
                toggle
                readOnly={!isEditable}
                onChange={handleChange}
              />
            }
            <label>{isEditable ? "Default" : editState.isDefault ? "Default: Yes" : "Default: No"}</label>
            {isEditable &&
              <Form.Checkbox
                name="isDefault"
                defaultChecked={editState.isDefault === true}
                toggle
                readOnly={!isEditable}
                onChange={handleChange}
              />
            }

          </Form.Group>
          {isEditable &&
            <><Form.Button color="blue" disabled={inProgress} icon>
              <Icon name='save' />
              Save</Form.Button>

            </>
          }
          {!isEditable && (
            <Form.Button color="blue" icon onClick={handleOnClickEdit}>
              <Icon name="edit" /> Edit
            </Form.Button>
          )}
        </Form>
        <Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>
        <Dimmer active={dimmerActive} page>
          <Header as="h2" icon inverted>
            <Icon name="check" color="green" />
            Saved successfully!
            <Header.Subheader>Redirecting you to list page</Header.Subheader>
          </Header>
        </Dimmer>
      </Segment>
    </div>
  )
}

export default AdminEditComponent;