import { Header, } from 'semantic-ui-react'
import { Route, Routes } from 'react-router';
import CodeTableList from './components/codetable-list.component';
import CodeTableEditComponent from './components/codetable-edit.component';

const CodeTablePage = () => {


 const GetPageRoutes = function() {
        return (
          <Routes>
            <Route path=":id" element={<CodeTableEditComponent />}/>
            <Route path="new" element={<CodeTableEditComponent />} />
            <Route path="/" element={<CodeTableList />}/>
          </Routes>
        )
 } 
 
 return (
   <div>
    <Header as='h2' className='left' textAlign='left'>
        <Header.Content>
            Code Reference Table
            <Header.Subheader>
            Manage code reference / lookup tables used across the application. <br></br>Each code table maps to a dropdown, and can have a list of values.
            </Header.Subheader>
        </Header.Content>
    </Header>
    <GetPageRoutes />
   </div>
 );
};

export default CodeTablePage;