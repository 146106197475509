import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import DataTable from "../../../components/data-table/data-table";
import { getFundingEligibilityList } from "../funding-eligibility.service";

const FundingEligibilityList = () => {
    const defaultRow = {
        data: [
        ]
    }
   
    const emptyRow = useMemo(
        () => ( 
          { id: undefined, name: '', isActive: true, description: '' }
        ),[])

    const navigate = useNavigate();

    const [tableData, setTableDataState] = useState(defaultRow)
    const colDefinitions = [
        { name: 'Id', field: 'id', type: 'text' },
        { name: 'Program Name', field: 'fundingProgramName', type: 'text' },
        { name: 'Type Of Project', field: 'typeOfProjectName', type: 'text' },
        { name: 'Project Category', field: 'projectCategoryName', type: 'text' },
        { name: 'Implementing Entity', field: 'eligibleRecipients', type: 'text' },
        { name: 'Enabled', field: 'isActive', type: 'boolean' },
    ];
    const actionDefinitions = [
        { name: 'Edit', icon: 'edit', onClick: (evt, rowIndex, rowId) => { 
            navigate(`/admin/funding-eligibility/${rowId}`) } }
    ]
    const tableActionDefinitions = [
        { name: 'Add Row', icon: 'add', route: '/admin/funding-eligibility/new', color: 'green', placeIn: 'header' }
    ]

    useEffect(() => {
      getFundingEligibilityList((result) => {
            if (result.data) {
              setTableDataState({ data: result.data });
            }
          })
        
    }, []);

    return (
        <div style={{height: "100vh"}}>
            <Divider hidden />

            <DataTable
                colDefinitions={colDefinitions}
                rowData={tableData.data}
                name=""
                rowActions={actionDefinitions}
                tableActions={tableActionDefinitions}
                emptyRow={emptyRow}
            />
        </div>
    )
}

export default FundingEligibilityList;