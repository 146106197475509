import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    Icon,
    Label,
    Segment,
    Dimmer,
    Header,
  } from "semantic-ui-react";
import DataTable, { sortData } from "../../../components/data-table/data-table";
import { dimDelayNavigate } from "../../../utils/common-utils";
import {  deletePcaTaxonomy, getTaxonomyList, saveTaxonomyItems } from "../pca.service";

const PCACriteriaItems = (props: any) => {
    const emptyRow = { id: undefined, 'name': '', 'isActive': true, "editable": true };
    const defaultRow = {
        data: [
            emptyRow,
        ]
    }
    const [dimmerActive, setDimmerActive] = useState(false)
    const [dimmerDeleteActive, setDimmerDeleteActive] = useState(false)
    const [dimmerFailed, setDimmerFailed] = useState(false)
    const [tableData, setTableDataState] = useState(defaultRow)
    const parentData = props.parentData;
    const pcaId = props.pcaId
    const projectComponentId = props.projectComponentId
    const componentType = props.name
    const parentId = props.parentId
    const taxonomyType = props.taxonomyType
    const childTaxonomyType:string = props.childTaxonomyType
    const parentPrefix = props.parentPrefix
    const routePrefix = `${parentPrefix}/${parentId}`
    const colDefinitions = [
        { name: componentType, field: 'name', type: 'text' },
        { name: 'Description', field: 'description', type: 'text' },
        { name: 'Enabled', field: 'isActive', type: 'toggle' },
    ];

    const tableActionDefinitions = [
        {name: 'Add Row', icon: 'add', type: 'add inline', color: 'green', placeIn: 'header'}
    ]
    const navigate = useNavigate();

    const [inProgress,setInProgress]=useState(false) 

    useEffect(() => {
        getTaxonomyList(pcaId,taxonomyType, parentId,(result)=>{
                
            if (result.data) {
                        const newStateData = result.data.map(rowItem => {
                            rowItem.editable = true
                            return rowItem;
                        })
                        const sortedData = sortData(newStateData, 'displaySequence', true)
                        setTableDataState({ data: sortedData })
                    }
           
        })
       
    }, [])

    if (!props.pcaId) {
        return null;
    }

    const handleOnClickViewDetail = (evt, rowIndex, rowId) => {
        if(rowId) {
            navigate(`${routePrefix}/${taxonomyType}/${rowId}`)
        }

    }
    const rowActionDefinitions = [
        {name: `View ${childTaxonomyType} detail`, icon: 'list', type: 'viewchild', color: 'yellow', onClick: handleOnClickViewDetail},
        {
            name: "Delete",
            icon: "trash",
            color: 'red',
            onClick: (evt, rowIndex, rowId) => {
              handleOnClickDelete(rowId)
            },
            
          },
    ]
    const handleOnClickDelete= (rowId:any) => {
  
        deletePcaTaxonomy(rowId, (result: any) => {
        
        if (result.data.statusCode == 202) {
           
            dimDelayNavigate(setDimmerDeleteActive, () =>result?.data?.message )
            window.location.reload()
         } 
         else
         {
          dimDelayNavigate(setDimmerFailed, () =>result?.data?.message )
         }
       })
       };

    const handleFormSubmit = () => {
        // return;
        const allowedProps = ['id', 'name', 'isActive', 'parentId']
        const itemValues = tableData.data
        .filter(codeValue => codeValue.name)
        .map(codeValue => {
            let rowData: { [key: string]: any } = {}
            allowedProps.forEach(propName => rowData[propName] = codeValue[propName])
            rowData['pcaId'] = pcaId
            rowData['parentId'] = parentId
            return rowData
        })

        setInProgress(true)
        try {
            saveTaxonomyItems(itemValues, pcaId, taxonomyType, projectComponentId, (result: any) => {
                if ([201, 200].includes(result.status)) {
                   
                    dimDelayNavigate(setDimmerActive, () => {navigate(routePrefix)})
               

                }
                else {
                    console.log(result.message);
                }
            }).then(() => {
                // console.log("saved")
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setInProgress(false)
              }
              );
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Segment>
            <Label as='a' color='blue' ribbon>
                {componentType}
            </Label>
            <DataTable
                colDefinitions={colDefinitions}
                rowData={tableData.data}
                name=""
                onFormSubmit={handleFormSubmit}
                handleStateUpdate={setTableDataState}
                allowEdit={true}
                tableActions={tableActionDefinitions}
                rowActions={rowActionDefinitions}
                reorderOn="displaySequence"
            />

<Dimmer active={inProgress} page>
          <Header as="h2" icon inverted>
            <Icon name="spinner" color="orange" size="big" />
            Saving
          </Header>
        </Dimmer>
            <Dimmer active={dimmerActive} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="green" />
                Saved successfully!
                <Header.Subheader>Redirecting you to list page</Header.Subheader>
            </Header>
            </Dimmer>
            <Dimmer active={dimmerDeleteActive} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="green" />
              Deleted Successfully!
            </Header>
            </Dimmer>
            <Dimmer active={dimmerFailed} page>
            <Header as='h2' icon inverted>
                <Icon name='check' color="red" />
                All referenced data must be deleted to delete this record!
            </Header>
            </Dimmer>
        </Segment>
        
    )
}
export default PCACriteriaItems;