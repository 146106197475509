import { Header, } from 'semantic-ui-react'
import { Route, Routes } from 'react-router';
import FundingEligibilityList from './components/funding-eligibility-list.component';
import FundingEligibilityEditComponent from './components/funding-eligibility-edit.component';

const FundingEligibilityPage = () => {


 const GetPageRoutes = function() {
        return (
          <Routes>
            <Route path=":id" element={<FundingEligibilityEditComponent />}/>
            <Route path="new" element={<FundingEligibilityEditComponent />} />
            <Route path="/" element={<FundingEligibilityList />}/>
          </Routes>
        )
 } 
 return (
   <div>
    <Header as='h2' className='left' textAlign='left'>
        <Header.Content>
            Funding Eligibility Map
            <Header.Subheader>
              <p> Manage Funding Eligibility Mapping between programs and eligibility criteria</p>
            </Header.Subheader>
        </Header.Content>
    </Header>
    <GetPageRoutes />
   </div>
 );
};

export default FundingEligibilityPage;