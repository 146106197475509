import { Component } from "react";
import PropTypes from "prop-types";
import { Menu, Container, Icon, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

export function buildMenuItems(props) {

  let menuItems = [];
  for (let i = 0; i < props.items.length; i++) {
    if (props.items[i].length !== 2) {
      console.error('HeaderMenu: items format should be ["name", "route"]');
      break;
    }
    const name = props.items[i][0];
    const route = props.items[i][1];
    menuItems.push(
      <Menu.Item
        key={"item-" + i}
        index={i}
        as={Link}
        to={route}
        header={i === 0}
        active={route === props.location?.pathname}
      >
        {name}
      </Menu.Item>
    );
  }
  return menuItems
}

class SidebarMenu extends Component<SidebarMenuProps> {

  render() {
    // const headerIcon = <Icon name={this.props.headerIcon} size="large" />;


    let menuItems = buildMenuItems(this.props);
    
    return (
      <Menu inverted vertical fluid compact style={{height: '100vh'}} >
        <Container>
          <Header as='h1' color='blue' textAlign='center'>
            HR<span><sub>&</sub></span>A <span>IIT CMS</span>
          </Header>
          {menuItems}
          <div style={{position:'absolute', bottom:'0', width:'100%'}}>
            <Menu.Item  as={Link} to="/admin/logout"><Icon name="sign-out" size="big"/>Log out</Menu.Item>
          </div>
        </Container>
      </Menu>
    );
  }
}
//          {i === 0 ? headerIcon : ""}

export interface StrictSidebarMenuProps {
    onItemClick?: typeof PropTypes.func;
    headerIcon?:   any;
    items: any[];
    vertical?: boolean;

}

export interface SidebarMenuProps extends StrictSidebarMenuProps {
    [key: string]: any
}

export default SidebarMenu;
