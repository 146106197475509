import Axios from "axios";
import { buildAPIURL, getAxiosDefaultOptions } from "../../utils/common-utils";


export async function getFundingProgramList(callback: any) {
  const options = getAxiosDefaultOptions();
  await Axios.get(buildAPIURL(`/funding-program`), options).then(async (response) => {
      callback(response);
  })
      .catch(function (error) {
          if (error.response) {
              callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
          }
      })
};

export async function getFundingById(id: number,callback: any) {
  const options = getAxiosDefaultOptions();
  await Axios.get(buildAPIURL(`/funding-program/${id}`), options).then(async (response) => {
      callback(response);
  })
      .catch(function (error) {
          if (error.response) {
              callback({ status: error.response.status, message: error.response.data?.error ? error.response.data?.error : "General server error" })
          }
      })
};

export interface FundingProgram {
  id?: number;
  name: string;
  eligibleRecipients: string;
  purpose: string;
  fundingAmountText: string;
  programAgency: string;
  link: string;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export async function saveFundingProgram(data: FundingProgram, callback: any) {
  const options = getAxiosDefaultOptions();
  const requestMethod = data.id ? Axios.patch : Axios.post;
  const pathSuffix = data.id ? `/${data.id}` : "";
  await requestMethod(buildAPIURL(`/funding-program${pathSuffix}`), data, options)
    .then(async (response) => {
      callback(response);
    })
    .catch(function (error) {
      if (error.response) {
        callback({
          status: error.response.status,
          message: error.response.data?.error
            ? error.response.data?.error
            : "General server error",
        });
      }
    });
}
