import axios from "axios";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getDefaultLocalStorageOptions } from "../utils/common-utils";

import { useLocalStorage } from "./useLocalStorage";


interface AuthContextType {
  user: any;
  login: (any, string?) => void;
  logout: (string?) => void;
}
const defaultContext: AuthContextType = undefined
const AuthContext = createContext(defaultContext);

export const AuthProvider = ({ children }) => {
  const appEnv = process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV : ''
  const lsKey = `ifncms-${appEnv}-u`
  const options = getDefaultLocalStorageOptions(true);
  const [user, setUser] = useLocalStorage(lsKey, null, options);
  const navigate = useNavigate();

  const login = async (data: any, redirectTo?: string) => {
    setUser(data);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    if (redirectTo) {
      navigate(redirectTo, { replace: true });
    }
  };

  const logout = (redirectTo?: string) => {
    setUser(null);
    if (redirectTo) {
      navigate("/", { replace: true });
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
