
export interface SelectOption {
    text: string;
    value: any;
}
export enum CODETABLE_TYPES {
    PRIORITY_AREAS = 'federal_priority_area',
    PROJECT_TYPES = 'type_of_project',
    PROJECT_CATEGORY = 'project_category',
    IMPLEMENTING_ENTITY = 'implementing_entity',
    ELIGIBLE_ACTIVITY = 'eligible_activity',
    PROJECT_LOCATION = "project_location",
    KEYWORD = "keywords",
}
