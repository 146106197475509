import { Navigate, Route, Routes } from 'react-router-dom'
import SignOutComponent from './components/auth/signout'
import AdminLayout from './layouts/admin.layout'
import LoginPage from './pages/login/login.page'

const RoutesComponent = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/admin/login" element={<LoginPage />} />
                <Route path="/admin/logout" element={<SignOutComponent />} />
                <Route
                    path="/admin/*"
                    element={<AdminLayout />}
                />
                <Route
                    path="*"
                    element={<Navigate to="/admin" replace />}
                />
            </Routes>
        </div>
    )
}

export default RoutesComponent