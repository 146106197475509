import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import DataTable from "../../../components/data-table/data-table";
import { useAuth } from "../../../hooks/useAuth";
import { getUsersList } from "../admin.service";


const AdminList = () => {
  const { user } = useAuth()
  const defaultRow = {
    data: [{}],
  };

  const roletype="admin"
 
  const navigate = useNavigate();
  const [userLogin, setUSerLogin] = useState<any>(
    user?.userRoles?.split(',')?.includes?.('admin')

      ?

      {
        name: "Add User",
        icon: "add",
        route: "/admin/users/admin",
        color: "green",
        placeIn: "header",

      }
      :

      {
        name: "Add User",
        icon: "add",
        route: "/admin/users/new",
        color: "green",
        placeIn: "header",

      }
  )
  const [userEdit,setUserEdit]=useState<any>(
    user?.userRoles?.split(',')?.includes?.('admin')

    ?

    {
      name: "Edit",
      icon: "edit",
      onClick: (evt, rowIndex, rowId) => {
        navigate(`/admin/users/${rowId}/admin`);
      },
      
    }
    :

    {
      name: "Edit",
      icon: "edit",
      onClick: (evt, rowIndex, rowId) => {
        navigate(`/admin/users/${rowId}`);
      },
      
    }
  )

  const [tableData, setTableDataState] = useState(defaultRow);
  const colDefinitions = [
    { name: "ID", field: "id", type: "number" },
    { name: "First Name", field: "firstName", type: "text" },
    { name: "Last Name", field: "lastName", type: "text" },
    { name: "Organization", field: "organization", type: "text" },
    { name: "Position", field: "position", type: "text" },
    { name: "Email", field: "email", type: "text" },

  ];
  const actionDefinitions = [
    userEdit,
   
      
   
  ];


  const tableActionDefinitions = [
   
    userLogin,

    {
      name: "Export CSV",
      icon: "download",
      color: "green",
      placeIn: "header",
      CSVLink: 'tableData.data',
      type: 'csvdownload',
      options: { exportFileName: 'users-list.csv' }
    },

  ];

  useEffect(() => {
    getUsersList(roletype,(result) => {
      if (result.data) {

        setTableDataState({ data: result.data });
      }
    })

  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Divider hidden />
      <DataTable
        colDefinitions={colDefinitions}
        rowData={tableData.data}
        name=""
        rowActions={actionDefinitions}
        tableActions={tableActionDefinitions}

      />

    </div>
  );
};

export default AdminList;
