import './App.css';
import { AuthProvider } from './hooks/useAuth';
import RouteComponent from './routes';

const App = () => (
  <AuthProvider>
    <RouteComponent />
  </AuthProvider>
);

export default App;
