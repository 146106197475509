export interface SelectOption {
    text: string;
    value: any;
}
export enum CONTENT_TYPES {
    HOME_CARDS = 'home_cards'

}
export interface CardData {
    items: {
        title: string;
        display_title: string;
    }[];

}
export interface Content {
    id?: number;
    typeCode: string;
    title: string;
    isActive?: boolean;
    isDefault?: boolean;
    data: CardData;
}
