import { Header, } from 'semantic-ui-react'
import { Route, Routes } from 'react-router';
import PCAEditComponent from './components/pca-edit.component';
import PCAComponentEditComponent from './components/pca-component-edit.component';

const ProjectComponentPage = () => {


 const GetPageRoutes = function() {
        return (
          <Routes>
            <Route path=":projectComponentId" element={<PCAComponentEditComponent />}/>
            <Route path="new" element={<PCAEditComponent />} />
          </Routes>
        )
 } 
 return (
   <div>
    <Header as='h2' className='left' textAlign='left'>
        <Header.Content>
            PCA Project Component
            <Header.Subheader>
            Manage project components for a PCA <br></br>
            </Header.Subheader>
        </Header.Content>
    </Header>
    <GetPageRoutes />
   </div>
 );
};

export default ProjectComponentPage;